import React from 'react'
// import TeamTwo from "./team/TeamTwo";
// import TeamThree from "./team/TeamThree";
// import TeamRowTwo from "./team/TeamRowTwo";
// import TeamRowOne from "./team/TeamRowOne";
import BrandTwo from "../elements/BrandTwo";
import TestimonialTwo from "../blocks/testimonial/TestimonialTwo";
import TestimonialThree from "../blocks/testimonial/TestimonialThree";
import PageHelmet from "../component/common/Helmet";
// import Breadcrumb2 from "../elements/common/Breadcrumb2";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const Team = () => {
    return (
        <div>
            <PageHelmet pageTitle='About Us' />
            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--120 bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>A Few Words About Us</h2>
                                    </div>
                                </div>
                            </div>
                            <p>Redemption Psychiatry is an outpatient psychiatric group that was founded by Doctor Jason Friday to provide quality mental health treatment to children, adolescents, and adults across the Valley. We treat a wide range of psychiatric disorders and patient populations to accommodate the needs of one of the fastest growing areas in Arizona. We recognize the overwhelming need for psychiatric care and we are ready to meet the challenge of caring for our community with skill and compassion.</p>
                            <div style={{ padding: "20px" }}></div>
                            <p>The future of medicine is very high-tech and we see the value of utilizing technology to provide our clients with the information and convenience they have come to expect in our fast-paced lives. This allows us to spend more of our scheduled appointment time with our clients and less time collecting and documenting routine information. We want to use as little paper in the office as possible. For some clients this can be a very different experience, but we think you will appreciate the convenience of having prescriptions sent directly to the pharmacy, receiving automated text message appointment reminders, and filling out forms, checking appointments, signing documents, and getting patient education online.</p>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonia Area  */}
                <div className="rn-testimonial-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 style={{ marginBottom: '50px' }}>Our Mission:</h2>
                                </div>
                            </div>
                        </div>
                        <TestimonialTwo />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 style={{ marginBottom: '50px' }}>Our Vision:</h2>
                                </div>
                            </div>
                        </div>
                        <TestimonialThree />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Our Values:</h2>
                                </div>
                            </div>
                        </div>
                        <p>
                            <b>We are…</b>
                            <br />
                            <b>Patient Focused</b> – Providing quality patient care is our primary function, the patient is the center of our practice, all decisions that impact our patients shall always be towards the greatest good.
                                     <br />
                            <b>Economical</b> – We must be frugal and provide the best value to our patients and hold ourselves to the highest standards.
                                    <br />
                            <b>Innovative</b> – Staying at the forefront of the mental healthcare community means always finding new ways and leveraging technology to accomplish tasks.
                                    <br />
                            <b>Accountable</b> - We must hold ourselves responsible for our own actions. While maintaining compassion for extenuating circumstances, we also expect patients to be accountable.
                                <br />
                            <b>We have…</b>
                            <br />
                            <b>Integrity</b> – Always being honest in all we do, giving the most accurate information and not being afraid to tell the truth. Having the utmost moral integrity, especially in matters regarding patient confidentiality or financial matters.
                                    <br />
                            <b>Compassion</b> – Being understanding of persons in our care and conducting ourselves in an empathetic manner in all we do.
                                    <br />
                            <b>We deliver…</b>
                            <br />
                            <b>Quality</b> – We will rigorously perform our duties to the best of our ability and consistently deliver quality care to our patients.
                                    <br />
                        </p>
                    </div>
                </div>
                {/* End Testimonia Area  */}

                {/* Start Team Area  */}
                {/* <div className="rn-team-wrapper ptb--40 bg_color--5">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Providers</h2>
                                    </div>
                                </div>
                            </div>
                            <TeamRowOne column="col-lg-3" teamStyle="" item="1" />
                            <div style={{ padding: "20px" }}></div>
                            <TeamRowTwo column="col-lg-3" teamStyle="" item="3" />
                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}


            </main>
            {/* End Page Wrapper  */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </div>

    )
}

export default Team






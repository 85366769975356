import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Element } from 'react-scroll';

class Testimonial extends Component {
    render() {
        return (
            <Element name="patient_testimonials">
                <div style={{ paddingTop: "70px" }}></div>
                <button id="patient_testimonials" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                <div className="service-area ptb--20 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Patient Testimonials</h2>
                                    <p style={{paddingBottom: '50px'}}>Read what our patients have to say about their experience with ketamine infusion therapy.</p>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <Tabs>
                                            <TabPanel>
                                                <div className="rn-testimonial-content text-center">
                                                    <div className="inner">
                                                        <p style={{ fontSize: "25px" }}>"Ketamine changed my life. I've had success on and off with prescription medications but with the help of a series of ketamine treatments beginning last winter, I've been able to step back from suicidal thoughts and get treatment without checking into a behavioral health clinic. 2019 was the first winter in almost 10 years that I was able to enjoy and keep up with my everyday life. I'm genuinely grateful for Corinne and all of the Redemption team."</p>
                                                    </div>
                                                    <div className="author-info">
                                                        <h6><span> - Rebecca J.</span></h6>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-testimonial-content text-center">
                                                    <div className="inner">
                                                        <p style={{ fontSize: "25px" }}>"I started Ketamine in the beginning hesitantly because I was unsure of whether or not it would be a safe option for treatment... I took into account my symptoms and how much I wanted to get better and decided I would rather try and know whether it worked or not than not know at all. I soon found my PTSD receding and other symptoms that were inflamed to lower back to their normal or less than normal amount. The Ketamine also had a bonus for me because I suffer from back pain and during the infusions and after I felt a lot better with my pain levels. I would certainly recommend this treatment for anyone out there who is struggling, I certainly was grasping at straws and I did find some relief in this treatment option."</p>
                                                    </div>
                                                    <div className="author-info">
                                                        <h6><span> - Collin B.</span></h6>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="rn-testimonial-content text-center">
                                                    <div className="inner">
                                                        <p style={{ fontSize: "20px" }}>"Ketamine has given me a new outlook on my life and how strong I am to overcome OCD, panic disorder, and PTSD."</p>
                                                    </div>
                                                    <div className="author-info">
                                                        <h6><span> - Christina V.</span></h6>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="rn-testimonial-content text-center">
                                                    <div className="inner">
                                                        <p style={{ fontSize: "20px" }}>"Ketamine is a wonderful treatment. I suffered a horrendous loss: I was told to get in for a treatment, not knowing what it was. After researching it, I was very scared, I tried to cancel my appointment. However, the Ketamine Nurse, Jolene, asked me to come in just to talk about the process. She sat with me for over an hour and explained it twice. She did this two times! Nobody does that! I had plenty of questions, and she was able to put my mind at ease. Now every time I speak to Jolene, it is an absolute joy. I genuinely feel like she is helping me and she really cares about me. As for the ketamine itself, just Amazing. I have suffered from depression and anxiety my whole life. A few days after my first treatment people started to notice a difference in me. I hadn't noticed because I was still grieving. However, I did stop crying every day. I had a total of three treatments and my depression was at an all-time low, and my anxiety attacks have become almost non-existent. This has been a life-changing experience. My whole life, I have only known Anxiety, depression and stress... This treatment allows me to live a whole new life with a whole new outlook I have never known before. When people ask what has changed, I am actually proud to advocate this revolutionary process. I also recommend this to all my friends and family who suffer from Anxiety and depression."</p>
                                                    </div>
                                                    <div className="author-info">
                                                        <h6><span> - Erika H.</span></h6>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="rn-testimonial-content text-center">
                                                    <div className="inner">
                                                        <p style={{ fontSize: "20px" }}>"I didn't even realize how far down the rabbit hole I fell until after I had my ketamine infusion therapy. It substantially improved my quality of life. I've never needed a booster infusion and as a matter of fact, I managed to survive being laid off, breaking my ankle, and the death of both of my dogs sequentially within several months without losing it AND without the need for any type of intervention. I recently joked that that never would have happened if I didn't have my infusion therapy. I can now live life on life's terms. I wholeheartedly recommend ketamine infusion therapy if you want your life back <span role="img" aria-labelledby="heart">❤️</span>"</p>
                                                    </div>
                                                    <div className="author-info">
                                                        <h6><span> - Heather H.</span></h6>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="rn-testimonial-content text-center">
                                                    <div className="inner">
                                                        <p style={{ fontSize: "20px" }}>"Having suffered through the debilitating effects of depression for nearly 30 years, I know all too well the feeling of desperation that accompanies the search for treatment. I tried many medications in nearly every class over the course of the last year after my normal 'cocktail' of medication stopped working. Nothing helped – that is, until I began having Ketamine infusions. The first infusion allowed me to see a blurry light at the end of the tunnel. I began feeling positive and 'normal' again after 2-3 more infusions with the positive effects lasting a little longer each time. I now get a maintenance infusion every five weeks or so to keep my depression in check and to continue having a positive outlook on life for myself and for my family. I highly recommend Ketamine treatments from Redemption Psychology for anyone suffering from chronic depression. It’s a life changer!"</p>
                                                    </div>
                                                    <div className="author-info">
                                                        <h6><span> - Brandi K.</span></h6>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabList className="testimonial-thumb-wrapper">
                                                <Tab>
                                                    <div className="testimonial-thumbnail bg_color--5">
                                                        <div className="thumb">
                                                            <img src="/assets/images/client/testimonial-1.webp" alt="Testimonial Images" />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="testimonial-thumbnai bg_color--5">
                                                        <div className="thumb">
                                                            <img src="/assets/images/client/testimonial-2.png" alt="Testimonial Images" />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="testimonial-thumbnai bg_color--5">
                                                        <div className="thumb">
                                                            <img src="/assets/images/client/testimonial-1.webp" alt="Testimonial Images" />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="testimonial-thumbnai bg_color--5">
                                                        <div className="thumb">
                                                            <img src="/assets/images/client/testimonial-1.webp" alt="Testimonial Images" />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="testimonial-thumbnai bg_color--5">
                                                        <div className="thumb">
                                                            <img src="/assets/images/client/testimonial-1.webp" alt="Testimonial Images" />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="testimonial-thumbnai bg_color--5">
                                                        <div className="thumb">
                                                            <img src="/assets/images/client/testimonial-1.webp" alt="Testimonial Images" />
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </TabList>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        )
    }
}
export default Testimonial;
import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

const mapStyles = {
    width: '100%',
    height: '100%',
    borderBottomRightRadius: "6px",
    borderBottomLeftRadius: "6px",
};


export class GoogleMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offices: [{ lat: 33.5999394, lng: -112.0135042 },
            {
                latitude: 33.295507,
                longitude: -111.876810,
                name: <React.Fragment>Redemption Psychiatry<br /> Chandler</React.Fragment>,
                address: <React.Fragment>600 S Dobson Road, Suite 36-E <br /> Chandler, AZ 85224</React.Fragment>,
                directions: "http://maps.apple.com/?q=Redemption+Psychiatry+Chandler&sll=33.643983,-112.230209&z=10&t=s"
            },
            {
                latitude: 33.300253,
                longitude: -111.757823,
                name: <React.Fragment>Redemption Psychiatry<br />Gilbert</React.Fragment>,
                address: <React.Fragment>2730 S Val Vista Drive, Suite 146 <br /> Gilbert, AZ 85295</React.Fragment>,
                directions: "http://maps.apple.com/?q=Redemption+Psychiatry+Gilbert&sll=33.300253,-111.757823&z=10&t=s"
            },
            {
                latitude: 33.5999394,
                longitude: -112.0135042,
                name: <React.Fragment>Redemption Psychiatry<br />Phoenix</React.Fragment>,
                address: <React.Fragment>12424 North 32nd Street, Suite 101<br /> Phoenix, AZ 85032</React.Fragment>,
                directions: "http://maps.apple.com/?q=12424+North+32nd+Street&sll=33.5999394,-112.0135042&z=10&t=s"
            },
            {
                latitude: 33.37816,
                longitude: -111.7583914,
                name: <React.Fragment>Redemption Psychiatry<br />Mesa</React.Fragment>,
                address: <React.Fragment>3489 E Baseline Rd<br /> Gilbert, AZ 85234</React.Fragment>,
                directions: "http://maps.apple.com/?q=Redemption+Psychiatry+Mesa&sll=33.37816,-111.7583914&z=10&t=s"
            // },
            // {
            //     latitude: 33.3489741739169,
            //     longitude: -111.82042978660739,
            //     name: <React.Fragment>Redemption Psychiatry<br />Gilbert Counselors</React.Fragment>,
            //     address: <React.Fragment>1425 W. Elliot Road, Suite 201<br /> Gilbert, AZ 85295</React.Fragment>,
            //     directions: "http://maps.apple.com/?q=1425+West+Elliot+Road&sll=33.3489741739169,-111.82042978660739&z=10&t=s"
            // },
            // {
            //     latitude: 33.321551236791976,
            //     longitude: -111.88178605962126,
            //     name: <React.Fragment>Redemption Psychiatry<br />Gilbert Counselors on Ray</React.Fragment>,
            //     address: <React.Fragment>2340 W Ray Road, Suite 2<br /> Chandler, AZ 85224</React.Fragment>,
            //     directions: "http://maps.apple.com/?q=2340+West+Ray+Road&sll=33.321551236791976,-111.88178605962126&z=10&t=s"
            }],
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
        }
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    displayMarkers = () => {
        return this.state.offices.map((office, index) => {
            return <Marker key={index} id={index} position={{
                lat: office.latitude,
                lng: office.longitude,
            }}
                name={office.name}
                address={office.address}
                directions={office.directions}
                onClick={this.onMarkerClick} />
        })
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={10.9}
                style={mapStyles}
                initialCenter={{ lat: 33.4747345, lng: -111.900371 }}
            // {{ lat: 33.5999394, lng: -112.0135042 }}
            >
                {this.displayMarkers()}
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={this.onClose}
                >
                    <div>
                        <h4 style={{ textAlign: "left" }}>{this.state.selectedPlace.name}</h4>
                        <hr></hr>
                        <p>{this.state.selectedPlace.address}</p>
                        <button><a href={this.state.selectedPlace.directions}>Get Directions!</a></button>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_MAP_KEY)
})(GoogleMap);



import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import ModalVideo from 'react-modal-video';

const videoList = [
    {
        title: 'Sonoran Living',
        videoId: 'nQq3VIBQg7s',
        src: '/assets/images/about/Sonoran-Living.png'
    },
    {
        title: 'Fox 10 News Phoenix',
        videoId: 'fRShMOV0EQY',
        src: '/assets/images/about/Fox-10.png'
    },
    {
        title: 'American Health Front on NBC',
        videoId: 'cK4BnRw1EYM',
        src: '/assets/images/about/American-Health-Front.png'
    },
    {
        title: 'Success Files with Rob Lowe',
        videoId: 'JO-CzTxx0hQ',
        src: '/assets/images/about/Success-Files.png'
    },
]

function Videos() {
    const [isOpen, setOpen] = useState(false)
    const [videoId, setVideoId] = useState('cK4BnRw1EYM')
    return (
        <>
            <PageHelmet pageTitle='Videos' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--35" data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Videos</h2>
                                <p>All of Redemption Psychiatry's media appearances, please watch any video that interests you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}


            {/* Start Video Top Area  */}
            {videoList.map((item, i) => (
                <div className="rn-about-area pt--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <h2 className="title" style={{ textAlign: "center" }}>{item.title}</h2>
                                    <img src={item.src} alt="About Images" style={{ margin: 'auto', display: 'block' }} />
                                    <button className="video-popup position-top-center theme-color" onClick={() => {
                                        setVideoId(item.videoId)
                                        setOpen(true)
                                        }}>
                                            <span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
            <br /><br /><br /><br /><br /><br />
            {/* End Video Top Area  */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop style={{ bottom: 85 }} showUnder={150}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </>
    )
}

export default Videos
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "800px",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore: {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    showLess: {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    iconHover: {
        "&:hover": {
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function Spravato() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = event => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClick3 = event => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
    };
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open ? 'simple-popover' : undefined;
    const id3 = open ? 'simple-popover' : undefined;

    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText: <div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText: <div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 300, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        }
    }
    return (
        <div>
            <div className={classes.title}>
                1.	Spravoto is repurposed ketamine
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Pharmaceutical companies use a technique of “repurposing” in order to profit from existing FDA-approved medications as if they were new treatments even though they are already generic. In order to do this, they need to change something about the drug to make it “significantly” different, such as changing the route of administration, dosing, and/or chemical composition. In the case of Spravato, they did all three.
                    <br /><br />
                    <div>
                        <div className={classes.textCenter}>
                            <Button className={classes.buttonMargin} aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                Route changed from IV to Nasal
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography component="div" className={classes.typography}> Ketamine delivery was changed from an IV formulation to a nasal spray. Unfortunately, this is not the most effective method of ketamine administration because a significant amount of the medication ends up being swallowed, it is not as effective at symptom reduction, it requires more frequent dosing, there is no control over medication delivery, it has a higher rate of side effects, and there is greater risk of improper administration. An example of FDA approval for a route of administration change is Ritalin (methylphenidate), which is administered orally but converting it to a transdermal patch allowed methylphenidate to be repurposed as Daytrana.</Typography>
                            </Popover>
                            <Button className={classes.buttonMargin} aria-describedby={id2} variant="contained" color="primary" onClick={handleClick2}>
                                Dosing changed from variable to fixed
                            </Button>
                            <Popover
                                id={id2}
                                open={open2}
                                anchorEl={anchorEl2}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography component="div" className={classes.typography}> There are only two levels of Spravato dosing. It is recommended to try 56mg first and then if it is not effective, the dose can be increased to 84mg. IV ketamine is dosed based on weight, medical conditions, and response to treatment, so dosing can be tailored to the patient. An example of FDA approval for a dosage change is Revatio (sildenafil), which was approved for pulmonary hypertension at 20mg increments, but when it was found to help with erectile dysfunction, it was successfully repurposed as Viagra (sildenafil) by changing the dose to 25mg increments.</Typography>
                            </Popover>
                            <Button className={classes.buttonMargin} aria-describedby={id3} variant="contained" color="primary" onClick={handleClick3}>
                                Chemical changed to a left isomer of ketamine
                            </Button>
                            <Popover
                                id={id3}
                                open={open3}
                                anchorEl={anchorEl3}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography component="div" className={classes.typography}>Esketamine is not a new medication. It is what is called an isomer of ketamine, which is composed of a 50/50 (racemic) mixture of esketamine and arketamine. Most chemicals have isomers, which have exactly the same number and composition of molecules and are only oriented differently, like right and left hands. Basically, when a patient gets a ketamine infusion, half of the treatment is already esketamine, but it was awarded a new patent as if it were a new discovery. An example of FDA approval for a similar chemical change is Celexa (citalopram), which was approved for depression in 1998, and then the left-handed isomer, Lexapro (escitalopram), was subsequently repurposed in 2002.</Typography>
                            </Popover>

                        </div>
                        <div className={classes.textCenter}>
                            <Typography component="div">
                                Tap or click one of the buttons above to learn more!
                            </Typography>
                        </div>
                    </div>
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                2.	Approved only for Treatment-Resistant Depression and acute suicidal ideation.
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Despite all the research that shows the benefits of ketamine for so many different disorders, Spravato was only approved for treatment-resistant depression after 2 failed antidepressants and acute suicidal ideation. This is similar to the way Transcranial Magnetic Stimulation (TMS) was FDA approved in 2008 for treatment-resistant depression after only 1 failed antidepressant, and TMS has a greater response rate and long-term remission rate than Spravato. However, it took 8 years before TMS was covered by a majority of insurances, and not after 1 failed antidepressant as indicated by the FDA. Patients must fail 3-4 medications and a course of psychotherapy before insurance will cover TMS. We anticipate the same progression towards coverage to occur with Spravato. Most insurances will likely cover Spravato, but some may require 3-4 failed antidepressants and even a course of psychotherapy.
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                3.	Very expensive compared to Ketamine.
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Because ketamine has been generic for so many years and is still used so frequently, it is only a fraction of the cost of Spravato. Depending on the dose and frequency of treatment, the cost of Spravato is approximately 100 times the cost of IV ketamine. Even if an insurance decides to cover Spravato, patients may have to pay an extremely high deductible or co-insurance and the cost of administration and observation will be similar. Janssen pharmaceuticals offers a discount coupon that can significantly reduce the cost of the medication but will not affect the cost of administration and observation.
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                4.	Not a routine prescription.
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Unlike most “routine” medication approvals, Spravato is not routinely obtained at a retail pharmacy or administered at home and the FDA has imposed special requirements for its distribution and use. In order to even prescribe Spravato, a doctor must enroll in a Risk Evaluation and Mitigation Strategy (REMS) program. After the doctor prescribes Spravato and insurance approves (or the patient pays the cash price, about $600-$900 per use), Spravato has to be delivered to the doctor’s office from a specialty pharmacy, administered by the patient under supervision, and patients must be observed by qualified staff for 2 hours before they can be driven home. No other medication has ever been approved to be used in the same way, so it is understandable that insurance companies and doctors are having difficulty in implementing its use.
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                5.	Not normally given with supportive medications.
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Spravato has all the same potential side effects as IV ketamine, but without IV access or payment for administration, IV medications cannot be provided to mitigate side effects. We provide oral antinausea medications free of charge because without treatment, about 30% of patients would vomit during the procedure. More importantly, the required 2-hour observation is primarily to prevent an unsafe rise in blood pressure. Since the medication administration is all at once, it cannot be slowed or stopped like IV ketamine, so an unsafe situation requires a call to emergency services and ambulance transport to an emergency room. Additional emergency services would incur additional costs under the patient’s insurance plan. We have additional oral medications to attempt to prevent such a situation, but without IV access, the treatment may take too long to be effective.
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                6.	It is only for adults.
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Even though ketamine is safe to use in minors, the FDA approval was only for 18 and up. Many children and adolescents suffer from severe, treatment-resistant disorders just like adults do, and we have successfully treated minors who were in desperate need of help. Our success rate with IV and IM ketamine in minors is similar to what we see in adults.
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                7.	Must be used with an antidepressant at the same time.
            </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    The approval expressly indicates that a patient must be taking an antidepressant, so if a patient does not tolerate the medication, they cannot use Spravato. It is also important to note that the efficacy of Spravato was shown in combination, not as a stand-alone therapy.
                </div>
            </ReactTextCollapse>
        </div>
    );
}
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";



class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='PrivacyPolicy' />
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--13" data-black-overlay="5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title color-1">Form Completed Succesfully</h2>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Resource Area */}
        <div className="service-area ptb--60 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h4>Thank you for submitting your information; we have received it successfully. </h4>
                  <h6>One of our team members will review your submission and contact you by phone within 24 to 48 hours to discuss the next steps.<br />
                  During the call, we will confirm your details, discuss your health needs, and schedule your initial consultation. </h6>
                  <h6>If you have any questions or need to provide additional information, please don’t hesitate to contact us at <a href="tel:+16025603445">(602) 560-3445</a>
                  </h6> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        {/* End Resource Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />


      </React.Fragment>
    )
  }
}
export default PrivacyPolicy;
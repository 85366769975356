import React, { useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';

const RevolutionMentalHealth = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);

    const styles = {
        page: {
            backgroundColor: '#ae8c4d', // background for the entire page
            minHeight: '100vh', // Ensure it covers the full viewport height
            padding: '30px 50px', // Add some top and bottom padding
        },
        container: {
            backgroundColor: '#f3ebc5', // background for the content
            borderRadius: '8px',
            padding: '20px',
            boxShadow: '0 6px 10px rgba(0,0,0,0.1)', // Add a subtle shadow for depth
        },
        header: {
            textAlign: 'center',
            color: '#000000', // Black text
            fontFamily: 'Dancing Script, cursive',
            marginBottom: '0px',
        },
        sectionTitle: {
            textAlign: 'center',
            color: '#000000', // Black text
            marginTop: '40px',
            fontFamily: 'Dancing Script, cursive',
        },
        text: {
            textAlign: 'center',
            color: '#000000', // Black text
            fontFamily: 'Gupter, serif',
            fontWeight: '550',
        },
        link: {
            color: '#096acd', // Gold
        },
        image: {
            maxWidth: '300px', // Increased max width for the logo
            marginBottom: '20px',
        },
        hr: {
            border: '0',
            borderTop: '2px solid #DAA520', // Gold horizontal line
            margin: '40px 0',
        },
        list: {
            listStyleType: 'none',
            paddingLeft: '0', // Remove default padding
            textAlign: 'center', // Center the list items
            fontFamily: 'Gupter, serif',
            fontWeight: '550',
            color: '#000000', // Black text
        },
        listItem: {
            display: 'inline-block',
            marginRight: '15px', // Add some spacing between list items
            fontFamily: 'Gupter, serif',
            fontWeight: '550',
        },
        videoContainer: {
            padding: '0',
            margin: '0 auto', 
            position: 'relative',
            textAlign: 'center',
            width: '50%', // Set width to 50% of the parent container
        },
        videoPopup: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            fontSize: '40px',
            color: '#FFF',
            background: '#ae8c4d ', // Use a solid color for the background; 'primary' is not a valid value
            borderRadius: '50%', // Circular button
            border: 'none',
            width: '80px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        videoImage: {
            width: '50%',
            height: 'auto',
            display: 'block',
            margin: '0 auto',
            boxShadow: '0 0 30px 20px rgba(174, 140, 77, 0.7)',
            borderRadius: '10px', 
            overflow: 'hidden', 
        }
    };

    return (
        <div style={styles.page}>
            <Container style={styles.container}>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Image src="/assets/images/logo/revo-logo.png" alt="Revolution Mental Health Logo" fluid style={styles.image} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={11}>
                        <h1 style={styles.header}>Revolution Mental Health</h1>
                        <Row className="justify-content-center" style={styles.sectionTitle}>
                            <Col lg={12} style={styles.videoContainer}>
                                <Image src="/assets/images/podcast/revolution.png" alt="Podcast Trailer" style={styles.videoImage} />
                                <Button onClick={openModal} style={styles.videoPopup}>
                                    <span>▶</span>
                                </Button>
                                <ModalVideo channel='youtube' isOpen={isOpen} videoId='fVB7_UUF8ac' onClose={() => setIsOpen(false)} />
                            </Col>
                        </Row>
                        <br /><br />
                        <p style={styles.text}>
                            At Revolution Mental Health, we specialize in providing Ketamine Bolus treatments for patients who are already established with a Redemption Psychiatry provider. Our clinic is dedicated to those who have previously received IV Ketamine infusions and are looking to continue their treatment with Ketamine Bolus Therapy.
                        </p>
                        <hr style={styles.hr} />
                        <h2 style={styles.sectionTitle}>About Our Clinic</h2>
                        <p style={styles.text}>
                            Revolution Mental Health operates as part of the Redemption Psychiatry family, ensuring you receive the same high-quality care you’ve come to expect. Our experienced team is committed to providing a safe and supportive environment for your treatment.
                        </p>
                        <hr style={styles.hr} />
                        <h2 style={styles.sectionTitle}>Services</h2>
                        <p style={styles.text}>
                            Our Ketamine bolus injections provide a convenient and insurance-covered option for those seeking effective treatment for mental health conditions. Administered by our experienced medical professionals, these injections offer the benefits of ketamine therapy in a tailored and personalized approach. Discover the potential of Ketamine bolus injections for enhancing your emotional well-being.
                        </p>
                        <hr style={styles.hr} />
                        <h2 style={styles.sectionTitle}>Request a Consultation</h2>
                        <p style={styles.text}>
                            If you are already a Redemption Psychiatry patient and are interested in continuing your treatment with Ketamine Bolus Therapy, please reach out to us directly at (480) 801-7777 or email us at revolution@rpsych.com. One of our staff members will get in touch with you to schedule an appointment. If you are NOT already a current Redemption Psychiatry patient, please see the resources listed just below this to learn more about our services and how to become a patient.
                        </p>
                        <hr style={styles.hr} />
                        <h2 style={styles.sectionTitle}>Resources</h2>
                        <p style={styles.list}>
                            For more information about Ketamine treatments and their benefits, please visit our main ketamine website: <br />
                            <li style={styles.listItem}><a href="https://rpsych.com/ketamine" style={styles.link}> Ketamine Therapy Overview</a></li><br />
                            If you are interested in becoming a patient of Redemption Psychiatry, please fill out our new patient form: <br />
                            <li style={styles.listItem}><a href="https://rpsych.com/newpatients" style={styles.link}>New Patient Form</a></li>
                        </p>
                        <hr style={styles.hr} />
                        <h2 style={styles.sectionTitle}>Contact Us</h2>
                        <p style={styles.text}>
                            <strong>Address:</strong> 4920 S Alma School Rd #3 <br />  Chandler, 85248 <br />
                            <strong>Phone:</strong> (480) 801-7777 <br />
                            <strong>Email:</strong> revolution@rpsych.com <br />
                            <strong>Hours:</strong> 12noon to 8pm
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RevolutionMentalHealth;





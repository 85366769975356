import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    maxWidth: "800px",
  },
  textCenter: {
    textAlign: "center"
  },
  buttonMargin: {
    margin: "5px",
  }
}));

export default function SimplePopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = event => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClick4 = event => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClick5 = event => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);
  const id = open ? 'simple-popover' : undefined;
  const id2 = open ? 'simple-popover' : undefined;
  const id3 = open ? 'simple-popover' : undefined;
  const id4 = open ? 'simple-popover' : undefined;
  const id5 = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className={classes.textCenter}>
        <Button className={classes.buttonMargin} aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
          Occupational problems
      </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="div" className={classes.typography}> A condition that prevents you from working, finding a job, getting promoted, or even just being productive at work can cost thousands of dollars monthly.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id2} variant="contained" color="primary" onClick={handleClick2}>
          Educational problems
      </Button>
        <Popover
          id={id2}
          open={open2}
          anchorEl={anchorEl2}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="div" className={classes.typography}> Patients may fail classes, drop classes, or withdraw completely due to their symptoms. This can result in lost tuition, repeating courses, lost scholarships, delays in graduating, and even abandoning educational and career goals. The actual cost can be in the tens of thousands, but the intangible costs can be incalculable.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id3} variant="contained" color="primary" onClick={handleClick3}>
          Relationship disruption
      </Button>
        <Popover
          id={id3}
          open={open3}
          anchorEl={anchorEl3}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="div" className={classes.typography}>The impact on friends, family, and romantic relationships cannot be measured, but the cost is very real. Effective treatment may help prevent losing a friend, becoming estranged from family, breaking up a relationship, or even divorcing.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id4} variant="contained" color="primary" onClick={handleClick4}>
          Ineffective treatments
      </Button>
        <Popover
          id={id4}
          open={open4}
          anchorEl={anchorEl4}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="div" className={classes.typography}>Examine how much you’re spending on current treatments that aren’t working. Multiple medication trials, frequent doctor visits, and ongoing therapy appointments can be very costly, even if they are covered by insurance. Most of our patients have been able to reduce or eliminate ineffective treatments and save hundreds of dollars per month.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id5} variant="contained" color="primary" onClick={handleClick5}>
          Serious adverse events
      </Button>
        <Popover
          id={id5}
          open={open5}
          anchorEl={anchorEl5}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="div" className={classes.typography}>Hospitalizations, suicide attempts, and even death can result from inadequate response to treatment. The cost can be financially devastating and potentially immeasurable.</Typography>
        </Popover>
      </div>
      <div className={classes.textCenter}>
        <Typography component="div">
          Tap or click one of the buttons above to learn more!
        </Typography>
      </div>
    </div>
  );
}
import React, { Fragment } from "react";
import ReactStars from "react-stars";
import { useForm } from 'react-hook-form';
import Axios from "axios";
import NumberFormat from 'react-number-format';
import { Modal } from 'react-bootstrap'

export default function TellUsWhatYouThink() {
    const { register, handleSubmit, errors } = useForm();
    const [rating, setRating] = React.useState(false);
    const [open45, setOpen45] = React.useState(false);
    const [open123, setOpen123] = React.useState(false);
    const [tel, setTel] = React.useState("");

    const onSubmit = (data, e) => {
        Axios.post('/api/contactforms/create-feedback-email', data)
        e.target.reset(); // reset after form submit
        setTel("")
    }

    const ratingChanged = (newRating) => {
        setRating(newRating)
        if (newRating > 3) {
            setOpen45(true);
        }
        if (newRating < 4) {
            setOpen123(true);
        }
    }

    const handleClose = () => {
        setOpen45(false);
        setOpen123(false);
    };

    return (
        <Fragment>
            <div>
                <h2 style={{ textAlign: "center", color: "white" }}>Tell Us What You Think!</h2>
            </div>
            <div className="row" style={{ justifyContent: 'center' }}>
                <ReactStars count={5} onChange={ratingChanged} size={48} color2={'#ffd700'} half={false} />
            </div>
            <Modal show={open45} onHide={handleClose}>
                <Modal.Header closeButton onClick={handleClose}>
                    <h3 style={{ textAlign: "center", margin: "auto" }}>Thank You for rating us {rating} stars!</h3>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        We are thrilled you had such a good experience with us, if you would please leave us a positive review on google by clicking which office you visited to view the reviews page. Show us your review next time you visit the office to receive a free prize to show our appreciation.
                        <br />
                    </div>
                    <div className="row">
                        <div style={{ textAlign: 'center', marginTop: '10px' }} className="col-lg-6 col-md-12">
                            <a href="https://search.google.com/local/writereview?placeid=ChIJWcjaD3esK4cRVEEafhTUVYA" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="rn-button-style--2 btn-solid"
                                    style={{ padding: '5px 15px' }}
                                    type="button">
                                    Gilbert<br /><span style={{ fontSize: '0.7rem' }}>(480) 542-0202</span>
                                </button>
                            </a>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }} className="col-lg-6 col-md-12">
                            <a href="https://search.google.com/local/writereview?placeid=ChIJl74JGrkAK4cRNfLRySQugUQ" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="rn-button-style--2 btn-solid"
                                    style={{ padding: '5px 15px' }}
                                    type="button">
                                    Chandler<br /><span style={{ fontSize: '0.7rem' }}>(480) 542-0222</span>
                                </button>
                            </a>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }} className="col-lg-6 col-md-12">
                            <a href="https://search.google.com/local/writereview?placeid=ChIJ2aHNtqytK4cRKk6HuSAeDM4" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="rn-button-style--2 btn-solid"
                                    style={{ padding: '5px 15px' }}
                                    type="button">
                                    Mesa<br /><span style={{ fontSize: '0.7rem' }}>(480) 542-1212</span>
                                </button>
                            </a>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }} className="col-lg-6 col-md-12">
                            <a href="https://search.google.com/local/writereview?placeid=ChIJz6dGagBzK4cRgn75KUSPDhc" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="rn-button-style--2 btn-solid"
                                    style={{ padding: '5px 15px' }}
                                    type="button">
                                    Phoenix<br /><span style={{ fontSize: '0.7rem' }}>(602) 560-3505</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={open123} onHide={handleClose}>
                <Modal.Header closeButton onClick={handleClose}>
                    <h3 style={{ textAlign: "center", margin: "auto" }}>We're sorry for your {rating} star experience!</h3>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        Please tell us about your negative experience and how we can improve for the future.
                        <br /><br />
                    </div>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="item01" className="col-12">
                                <input
                                    type="text"
                                    name="name"
                                    id="item01"
                                    placeholder="Your Name *"
                                    ref={register({
                                        required: 'Please enter your name'
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.name && errors.name.message}</div>
                            </label>
                            <label htmlFor="item02" className="col-12">
                                <input
                                    type="text"
                                    name="email"
                                    id="item02"
                                    placeholder="Your email *"
                                    ref={register({
                                        required: 'Please enter your email',
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.email && errors.email.message}</div>
                            </label>
                            <label htmlFor="item03" className="col-12">
                                <NumberFormat
                                    name="tel"
                                    id="item03"
                                    value={tel}
                                    onChange={e => setTel(e.target.value)}
                                    placeholder="(555) 555-5555"
                                    format="(###) ###-####"
                                    mask="_"
                                    getInputRef={register({
                                        pattern: {
                                            value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                                            message: 'Invalid phone number',
                                        },
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.tel && errors.tel.message}</div>
                            </label>
                            <label htmlFor="item04" className="col-12">
                                <textarea
                                    style={{ padding: '5px 15px 5px 15px' }}
                                    type="text"
                                    id="item04"
                                    name="message"
                                    placeholder="Your Message"
                                    ref={register({
                                        required: 'Please write a message you would like to send us.'
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.message && errors.message.message}</div>
                            </label>
                            <button style={{ display: 'block', margin: '0 auto' }} className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Send</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
export var portfolioSlick = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 420,
    settings: {
      slidesToShow: 1
    }
  }]
};
export var portfolioSlick2 = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 993,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 769,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 481,
    settings: {
      slidesToShow: 1
    }
  }]
};
export var slickDot = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 993,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 580,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 481,
    settings: {
      slidesToShow: 1
    }
  }]
};
export var slideSlick = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  fade: true,
  easing: "fade",
  adaptiveHeight: true
};
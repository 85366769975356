import React, { useState, useEffect } from "react";
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import { FiPhoneCall, FiChevronUp, FiFolder, FiUsers, FiPackage, FiDollarSign } from "react-icons/fi";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ConsenttoTreatPatient from '../../public/assets/pdfs/ConsenttoTreatPatient.pdf'
import ConsenttoTreatGuardian from '../../public/assets/pdfs/ConsenttoTreatGuardian.pdf'
import CourtLegalPolicy from '../../public/assets/pdfs/CourtLegalPolicy.pdf'
import LegalConsentforChildAdolescentClients from '../../public/assets/pdfs/LegalConsentforChild-AdolescentClients.pdf'
// import MedicationAdherenceAgreement from '../../public/assets/pdfs/MedicationAdherenceAgreement.pdf'
import NoticeofPrivacyPractices from '../../public/assets/pdfs/NoticeofPrivacyPractices.pdf'
import NoticeofPrivacyPracticeswithAcknowledgement from '../../public/assets/pdfs/NoticeofPrivacyPracticeswithAcknowledgement.pdf'
import PrivatePayAgreement from '../../public/assets/pdfs/PrivatePayAgreement.pdf'
import ReleaseofInformation from '../../public/assets/pdfs/ReleaseofInformation.pdf'


const Testimonial = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [tel, setTel] = React.useState("");
    const [newAllergy, setNewAllergy] = useState("")
    const [pharmacy, setPharmacy] = useState("")
    const [pharmacyType, setPharmacyType] = useState("")
    const [mailOrderPharmacy, setMailOrderPharmacy] = useState("")
    const [localPharmacy, setLocalPharmacy] = useState("")
    const [contact, setContact] = useState("")
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);

    const onSubmit = (data, e) => {
        Axios.post('/api/contactforms/create-refill-email', data)
        console.log(data)
        e.target.reset(); // reset after form submit
        setOpen2(false)
        setOpen4(true)
        setTel("")
        updateIndex(0)
    }
    const defaultList = [
        { name: "med0" },
    ];

    const [list, updateList] = useState(defaultList);
    const [index, updateIndex] = useState(1);


    const handleRemoveItem = (e) => {
        e.preventDefault()
        const name = e.target.getAttribute("name")
        updateList(list.filter(item => item.name !== name));
    };
    const handleAddItem = (e) => {
        e.preventDefault()
        updateList(list.length < 10 ? [...list, { name: 'med' + index }] : [...list]);
        updateIndex(index + 1)
    };
    const handleClose = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
    };

    useEffect(() => {
        setNewAllergy(watch('newAllergy'))
        setPharmacy(watch('pharmacy'))
        setPharmacyType(watch('pharmacyType'))
        setMailOrderPharmacy(watch('mailOrderPharmacy'))
        setLocalPharmacy(watch('localPharmacy'))
        setContact(watch('contact'))
    }, [watch])
    const ServiceListOne = [
        {
            icon: <FiUsers />,
            title: 'Patient Portal',
            description: 'Click here to be re-directed to your Valant Patient Portal',
            description2: '(Click here for help with Patient Portal)',
            href: 'https://www.valant.io/Portal/rp',
            href2: 'https://www.valant.io/myio-help/#UserGuide',
            target: '_blank'
        },
        {
            icon: <FiDollarSign />,
            title: 'Make a Payment Online',
            description: 'Fast and secure way to make a payment, just click here and fill out the following form.',
            href: 'https://www.patientnotebook.com/redemption/Enhanced/SendMoney/MakePayment',
            target: '_blank'
        },
        {
            icon: <AiOutlineMedicineBox />,
            title: 'Medication Refills',
            description: 'Request a medication refill using this form.',
            modal: () => setOpen2(true)
        },
        {
            icon: <FiFolder />,
            title: 'Patient Forms',
            description: 'View/Download/Print our patient forms as needed.',
            modal: () => setOpen5(true)
        },
        {
            icon: <FiPhoneCall />,
            title: 'Crisis Hotlines',
            description: 'View a list of 24/7 crisis hotlines',
            modal: () => setOpen(true)
        },
        {
            icon: <FiPackage />,
            title: 'Referral Links',
            description: 'Links to other websites such as Counselors, Hospitals, Treatment Centers, etc.',
            modal: () => setOpen3(true)
        },
        // {
        //     icon: <FiUsers />,
        //     title: 'Fee & Cancellation Policy',
        //     description: 'Click to read our Fee & Cancellation Policy',
        //     modal: () => setOpen1(true)
        // },
        // {
        //     icon: <FiUsers />,
        //     title: 'Off-Hours/On-Call Information',
        //     description: 'Give a list of off-hours, on-call information, dan will provide content for this',
        //     href: '/currentpatients',
        //     target: '_self'
        // },
    ]
    return (
        <>
            <PageHelmet pageTitle='Resources' />
            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--15" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Useful Resources</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Service Area */}
            <div className="service-area ptb--30 bg_color--1">
                <div className="container">
                    <div className="row service-one-wrapper">
                        {ServiceListOne.map((val, i) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i} style={{textAlign: 'center'}}>
                                {val.href ?
                                    <>
                                        <a className="text-center" href={val.href} target={val.target} rel="noopener noreferrer">
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a className="text-center" target="_blank" rel="noopener noreferrer" href={val.href2}>{val.description2}</a>
                                    </>
                                    :
                                    <button style={{ border: '0' }} className="text-center" onClick={val.modal}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </button>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Service Area */}

            {/* Start Modal Area */}
            <Modal show={open} size='lg' onHide={handleClose}>
                <Modal.Body>
                    <a href="https://988lifeline.org/" target="_blank" rel="noopener noreferrer"><img src="https://news.nhcgov.com/wp-content/uploads/2022/07/988.png" alt="Logo Images" /></a>
                    <h3 style={{ textAlign: 'center' }}>Crisis Lines:</h3>
                    <p style={{ fontSize: 13 }}><strong>National Suicide Crisis Hotline: </strong>1-800-273-TALK</p>
                    <p style={{ fontSize: 13 }}><strong>Maricopa County - Crisis Response Network: </strong>1-800-631-1314, 602-222-9444</p>
                    <p style={{ fontSize: 13 }}><strong>Empact Crisis: </strong>480-784-1500</p>
                    <p style={{ fontSize: 13 }}><strong>Pima County Crisis Line:  </strong>1-800-796-6762</p>
                    <p style={{ fontSize: 13 }}><strong>Graham, Greenlee, Santa Cruz, Cochise, Pinal, Gila, La Paz, 	&#38; Yuma County Crisis Line: </strong>1-866-495-6735</p>
                    <p style={{ fontSize: 13 }}><strong>Apache, Coconino, Mojave, Navajo, and Yavapai County Crisis Line: </strong>1-877-756-4090</p>
                    <p style={{ fontSize: 13 }}><strong>Gila River and Ak-Chin Indian Communities: </strong>1-800-259-3449</p>
                    <br /><br />
                    <h3 style={{ textAlign: 'center' }}>Psychiatric Urgent Care Facilities:</h3>
                    <p style={{ fontSize: 13 }}><strong>Connections Arizona (UPC): </strong>602-416-7600</p>
                    <p style={{ fontSize: 13 }}><strong>Recovery Innovations of Arizona: </strong>602-650-1212</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {/* End Modal Area */}

            {/* Start Modal 1 Area */}
            <Modal show={open1} size='lg' onHide={handleClose}>
                <Modal.Body>
                    <h3 style={{ textAlign: 'center' }}>Fees &#38; Cancellation Policy:</h3>
                    <p style={{ fontSize: 13 }}>
                        When you make an appointment with our doctors or therapists, please understand that this is time that we reserve exclusively for you. We do not overbook or double book appointments to account for no shows. This allows us to avoid delays and provide you with better care. That is our commitment to you. Your commitment as a patient of Redemption Psychiatry is to call us as soon as you know you will be unable to make your appointment. Please give us notice at least 24 hours in advance if you will not be able to make your appointment. Failure to cancel in advance will result in a cancellation fee being assessed to your account: $150 for intakes and $75 for follow-ups (Please see our Clinic Policies and Procedures on your portal account). Your appointment will not be rescheduled until that fee is paid. Thank you for your understanding and cooperation.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {/* End Modal 1 Area */}

            {/* Start Modal 2 Area */}
            <Modal show={open2} size='lg' onHide={handleClose}>
                <Modal.Body>
                    <h3 style={{ textAlign: 'center' }}>Routine Medication Refill Request Form</h3>
                    <p style={{ fontSize: 13 }}>If you need a refill of your current medications before your next appointment, you may use this form to give our staff the information they need to process your request as soon as possible during the business day. If you don't have a follow up appointment scheduled, you need to call the office and schedule an appointment before requesting any medication refills.</p><br />
                    <div className="alert alert-primary alert-dismissible" style={{ marginBottom: '0px', marginTop: '-30px' }} role="alert">
                        Use of this form does not guarantee you will automatically receive every medication you request, requests are only processed during normal business hours. <br /> <hr />
                        <strong>DO NOT</strong> use this form if: <br />
                        <p style={{ marginLeft: '15px', fontSize: '13px' }}>A) You suspect a medication is causing side effects and/or wish to ask for dose adjustments. This needs to be discussed with your prescriber or with the Nurse. <br />
                            B) You wish to discuss changing to a different medication. This can only be reviewed and approved by your prescriber.<br />
                            C) <strong>If you do not have a follow-up appointment already scheduled with your provider</strong>, as we can only provide enough medication until your next appointment.</p>
                        <strong>If any of these situations apply to you, please contact your provider's office to schedule a follow-up appointment.</strong><br />
                        <div className="row" style={{ border: '2px', borderStyle: 'solid', marginLeft: '15px', marginTop: '10px' }}>
                            <div style={{ textAlign: 'center', marginTop: '10px' }} className="col-lg-3 col-md-12">
                                <a href="tel:+1-480-542-0202" target="blank">
                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        style={{ padding: '5px 15px' }}
                                        type="button">
                                        Gilbert<br /><span style={{ fontSize: '0.7rem' }}>(480) 542-0202</span>
                                    </button>
                                </a>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }} className="col-lg-3 col-md-12">
                                <a href="tel:+1-480-542-0222" target="blank">
                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        style={{ padding: '5px 15px' }}
                                        type="button">
                                        Chandler<br /><span style={{ fontSize: '0.7rem' }}>(480) 542-0222</span>
                                    </button>
                                </a>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }} className="col-lg-3 col-md-12">
                                <a href="tel:+1-480-542-1212" target="blank">
                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        style={{ padding: '5px 15px' }}
                                        type="button">
                                        Mesa<br /><span style={{ fontSize: '0.7rem' }}>(480) 542-1212</span>
                                    </button>
                                </a>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }} className="col-lg-3 col-md-12">
                                <a href="tel:+1-602-560-3505" target="blank">
                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        style={{ padding: '5px 15px' }}
                                        type="button">
                                        Phoenix<br /><span style={{ fontSize: '0.7rem' }}>(602) 560-3505</span>
                                    </button>
                                </a>
                            </div>
                        </div><br />
                        When this form is completed appropriately, you should receive a call from your pharmacy within 2-5 days to update you on the status of refilling your medication. You will only receive a call back from our staff if we have a question regarding your submission.
                    </div>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <br /><h4>Personal Information:</h4>
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-6 col-md-12">
                                    <label style={{ marginLeft: "0px" }} htmlFor="item01">Patient First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="item01"
                                        placeholder="First Name *"
                                        ref={register({
                                            required: '*Please enter your first name*',
                                            maxLength: {
                                                value: 30,
                                                message: 'Max length is 30',
                                            },
                                        })}
                                    />
                                    <div style={{ color: "red" }}>{errors.firstName && errors.firstName.message}</div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label style={{ marginLeft: "0px" }} htmlFor="item02">Patient Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        id="item02"
                                        placeholder="Last Name *"
                                        ref={register({
                                            required: '*Please enter your last name*',
                                            maxLength: {
                                                value: 30,
                                                message: 'Max length is 30',
                                            },
                                        })}
                                    />
                                    <div style={{ color: "red" }}>{errors.lastName && errors.lastName.message}</div>
                                </div>
                            </div>
                            <br />
                            <div className="row row--35">
                                <div className="col-lg-6 col-md-12">
                                    <label style={{ marginLeft: '0px' }} htmlFor="DOB">Patient Date of Birth:</label>
                                    <input
                                        name="DOB"
                                        id="DOB"
                                        type="date"
                                        ref={register({ required: '*Please enter your Date of Birth*' })}
                                    />
                                    <div style={{ color: "red" }}>{errors.DOB && errors.DOB.message}</div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label style={{ marginLeft: '0px' }} htmlFor="nextAppointment">Date of next appointment:</label>
                                    <input
                                        name="nextAppointment"
                                        id="nextAppointment"
                                        type="date"
                                        ref={register({ required: '*Please enter when your next appointment is scheduled*' })}
                                    /><br />
                                    <div style={{ color: "red" }}>{errors.nextAppointment && errors.nextAppointment.message}</div>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row row--35 align-items-start">
                                <div className="col-12">
                                    <h4>Medication Information:</h4>
                                    <label style={{ marginLeft: '10px' }}>Are there any changes to patients drug allergies?</label>
                                    <div className="row row--35" style={{ display: 'block', position: 'relative' }}>
                                        <div className="col-12" style={{ textAlign: 'left', marginLeft: '20px' }}>
                                            <label style={{ display: 'inline-block', position: 'relative' }} htmlFor="flexRadioDefault1">No
                                                <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '5px' }} type="radio" name="newAllergy" id="flexRadioDefault1" value="No" ref={register({ required: '*Please select the appropriate answer*' })} />
                                            </label>
                                            <label style={{ display: 'inline-block', position: 'relative', left: '75px', marginLeft: '75px' }} htmlFor="flexRadioDefault2">Yes
                                                <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '5px' }} type="radio" name="newAllergy" id="flexRadioDefault2" value="Yes" ref={register()} />
                                            </label>
                                            <div style={{ color: "red" }}>{errors.newAllergy && errors.newAllergy.message}</div>
                                            {newAllergy === "No" ?
                                                <div></div>
                                                : newAllergy === "Yes" ?
                                                    <div className="col-lg-6 col-md-10">
                                                        <label style={{ marginLeft: "0px" }} htmlFor="newAllergyName">The patient has new allergy/hypersensitivity to:</label>
                                                        <input
                                                            type="text"
                                                            name="newAllergyName"
                                                            id="newAllergyName"
                                                            placeholder="Name of medication *"
                                                            ref={register({
                                                                required: '*Please enter name of new medication allergy*',
                                                                maxLength: {
                                                                    value: 30,
                                                                    message: 'Max length is 30',
                                                                },
                                                            })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.newAllergyName && errors.newAllergyName.message}</div>
                                                    </div>
                                                    :
                                                    <div></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35">
                                <div className="col-12">
                                    <br />
                                    <label style={{ marginLeft: '10px' }}>Which Pharmacy should the prescription be sent to?</label>
                                    <div className="row row--35" style={{ display: 'block', position: 'relative' }}>
                                        <div className="col-12" style={{ textAlign: 'left', marginLeft: '20px' }}>
                                            <div className="row">
                                                <div className="col-lg-3 col-sm-12" style={{ paddingLeft: '6px' }}>
                                                    <label style={{ display: 'inline-block', position: 'relative' }} htmlFor="pharmacy1">Pharmacy on file
                                                        <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '-45px' }} type="radio" name="pharmacy" id="pharmacy1" value="Pharmacy on file" ref={register({ required: '*Please select the appropriate pharmacy*' })} />
                                                    </label>
                                                </div>
                                                <div className="col-lg-4 col-sm-12" style={{ paddingLeft: '6px' }}>
                                                    <label style={{ display: 'inline-block', position: 'relative' }} htmlFor="pharmacy2">A different pharmacy
                                                        <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '-62px' }} type="radio" name="pharmacy" id="pharmacy2" value="A different pharmacy" ref={register()} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{ color: "red" }}>{errors.pharmacy && errors.pharmacy.message}</div>
                                            {pharmacy === "Pharmacy on file" ?
                                                <div></div>
                                                : pharmacy === "A different pharmacy" ?
                                                    <div style={{ borderStyle: 'solid', borderWidth: '0px 0px 0px 2px', borderColor: 'lightgray' }}>
                                                        <label style={{ marginLeft: '10px' }}>Do you want this to go to a mail order or local pharmacy?</label>
                                                        <div className="row" style={{ display: 'block', position: 'relative' }}>
                                                            <div className="col-12" style={{ textAlign: 'left', marginLeft: '20px' }}>
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-sm-12" style={{ paddingLeft: '6px' }}>
                                                                        <label style={{ display: 'inline-block', position: 'relative' }} htmlFor="pharmacyType1">Local Pharmacy
                                                                            <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '-45px' }} type="radio" name="pharmacyType" id="pharmacyType1" value="Local Pharmacy" ref={register({ required: '*Please select the appropriate pharmacy type*' })} />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-12" style={{ paddingLeft: '6px' }}>
                                                                        <label style={{ display: 'inline-block', position: 'relative' }} htmlFor="pharmacyType2">Mail Order Pharmacy
                                                                            <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '-62px' }} type="radio" name="pharmacyType" id="pharmacyType2" value="Mail Order Pharmacy" ref={register()} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div style={{ color: "red" }}>{errors.pharmacyType && errors.pharmacyType.message}</div>
                                                                {pharmacyType === "Local Pharmacy" ?
                                                                    <div className="col-xl-6 col-lg-6 col-md-10 col-11" style={{ borderStyle: 'solid', borderWidth: '0px 0px 0px 2px', borderColor: 'lightgray' }}>
                                                                        <label>Select a Local Pharmacy</label>
                                                                        <select
                                                                            name="localPharmacy"
                                                                            ref={register({ required: '*Please select a pharmacy*' })}>
                                                                            <option value="Costco">Costco</option>
                                                                            <option value="CVS">CVS</option>
                                                                            <option value="Fry's">Fry's</option>
                                                                            <option value="Rexall">Rexall</option>
                                                                            <option value="Safeway">Safeway</option>
                                                                            <option value="Sam's Club">Sam's Club</option>
                                                                            <option value="Target">Target</option>
                                                                            <option value="Walgreens">Walgreens</option>
                                                                            <option value="Wal-Mart">Wal-Mart</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                        <div style={{ color: "red" }}>{errors.localPharmacy && errors.localPharmacy.message}</div>
                                                                        {localPharmacy === "Other" ?
                                                                            <div>
                                                                                <label>Other Local Pharmacy:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="otherLocalPharmacy"
                                                                                    placeholder="Name of Local Pharmacy *"
                                                                                    ref={register({
                                                                                        required: 'Please enter the name of the local pharmacy'
                                                                                    })}
                                                                                />
                                                                                <div style={{ color: "red" }}>{errors.otherLocalPharmacy && errors.otherLocalPharmacy.message}</div></div>
                                                                            :
                                                                            <div></div>}
                                                                        <label htmlFor="item05">Enter crossroads of preferred pharmacy location:</label>
                                                                        <input
                                                                            type="text"
                                                                            style={{ display: 'block' }}
                                                                            name="pharmacyCrossRoads"
                                                                            id="item05"
                                                                            placeholder="Crossroads of pharmacy *"
                                                                            ref={register({
                                                                                required: 'Please enter the crossroads of the pharmacy'
                                                                            })}
                                                                        />
                                                                        <div style={{ color: "red" }}>{errors.pharmacyCrossRoads && errors.pharmacyCrossRoads.message}</div>
                                                                    </div>
                                                                    : pharmacyType === "Mail Order Pharmacy" ?
                                                                        <div className="col-lg-6 col-md-8 col-11" style={{ borderStyle: 'solid', borderWidth: '0px 0px 0px 2px', borderColor: 'lightgray' }}>
                                                                            <label style={{ marginLeft: "10px" }}>Select a Mail Order Pharmacy</label>
                                                                            <select
                                                                                name="mailOrderPharmacy"
                                                                                ref={register({ required: '*Please select a Mail Order Pharmacy*' })}>
                                                                                <option value="Cigna(PA)">Cigna(PA)</option>
                                                                                <option value="CVS/Caremark(AZ)">CVS/Caremark(AZ)</option>
                                                                                <option value="Express Scripts(MO)">Express Scripts(MO)</option>
                                                                                <option value="OptumRx(CA)">OptumRx(CA)</option>
                                                                                <option value="Wal-Mart(TX)">Wal-Mart(TX)</option>
                                                                                <option value="Other">Other</option>
                                                                            </select>
                                                                            <div style={{ color: "red" }}>{errors.mailOrderPharmacy && errors.mailOrderPharmacy.message}</div>
                                                                            {mailOrderPharmacy === "Other" ?
                                                                                <div>
                                                                                    <label style={{ marginLeft: "0px" }}>Name of Mail Order Pharmacy:</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        name="otherMailOrderPharmacy"
                                                                                        placeholder="Name of Mail Order Pharmacy *"
                                                                                        ref={register({ required: '*Please enter the name of Mail Order Pharmacy*' })}
                                                                                    />
                                                                                    <div style={{ color: "red" }}>{errors.otherMailOrderPharmacy && errors.otherMailOrderPharmacy.message}</div></div>
                                                                                :
                                                                                <div></div>}
                                                                        </div>
                                                                        :
                                                                        <div></div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: '15px' }}>
                                {list.map(item => {
                                    return (
                                        <div className="row" key={item.name} style={{ borderColor: 'lightgray', borderWidth: '2px', borderStyle: 'solid', position: 'relative', margin: '0px 0px 10px 0px' }}>
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        Remove this medication
                                                    </Tooltip>
                                                }
                                            >
                                                <button style={{ borderStyle: 'solid', borderWidth: '2px', borderColor: 'gray', color: 'black', backgroundColor: 'rgba(255, 0, 0, .5)', position: 'absolute', top: '0', right: '0', zIndex: '2' }} name={item.name} onClick={handleRemoveItem}>X</button>
                                            </OverlayTrigger>
                                            <div className="col-md-6 col-sm-12">
                                                <br />
                                                <label style={{ marginLeft: "10px" }} htmlFor={item.name}>Name of Medication Requested:</label>
                                                <input
                                                    type="text"
                                                    style={{ maxWidth: '350px', display: 'block' }}
                                                    name={item.name}
                                                    id={item.name}
                                                    placeholder="Medication name *"
                                                    ref={register({ required: 'Please enter the name of medication requested' })}
                                                />
                                                <div style={{ color: "red" }}>{errors[item.name] && errors[item.name].message}</div>
                                                <br />
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <br />
                                                <label style={{ marginLeft: "10px" }} htmlFor={item.name}>Medicine strength (such as 0.25mg or 300mg):</label>
                                                <input
                                                    type="text"
                                                    style={{ maxWidth: '350px', display: 'block' }}
                                                    name={item.name + "Str"}
                                                    id={item.name}
                                                    placeholder="Medicine strength *"
                                                    ref={register({ required: 'Please enter the medicine strength' })}
                                                />
                                                <div style={{ color: "red" }}>{errors[item.name + "Str"] && errors[item.name + "Str"].message}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <button style={{ borderStyle: 'solid', borderWidth: '2px', borderColor: 'gray', color: 'black', backgroundColor: 'rgba(0, 255, 0, .5)' }} onClick={handleAddItem}>+ add another medication</button>
                            </div>
                            <div className="row row--35">
                                <div className="col-12">
                                    <br />
                                    <h4>Contact Information:</h4>
                                    <label style={{ marginLeft: '10px' }}>How would you like our nurses to contact you if they have a question about your refill request?</label>
                                    <div className="row row--35" style={{ display: 'block', position: 'relative' }}>
                                        <div className="col-12" style={{ textAlign: 'left', marginLeft: '20px' }}>
                                            <label style={{ display: 'inline-block', position: 'relative' }} htmlFor="contact1">Email
                                                <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '-5px' }} type="radio" name="contact" id="contact1" value="email" ref={register({ required: '*Please select a contact method*' })} />
                                            </label>
                                            <label style={{ display: 'inline-block', position: 'relative', left: '75px', marginLeft: '15px' }} htmlFor="contact2">Phone
                                                <input style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '-10px' }} type="radio" name="contact" id="contact2" value="phone" ref={register()} />
                                            </label>
                                            <div style={{ color: "red" }}>{errors.contact && errors.contact.message}</div>
                                            {contact === "email" ?
                                                <div className="col-lg-8 col-md-8 col-11">
                                                    <label style={{ marginLeft: "10px" }} htmlFor="item05">What is the best email to reach you at with any questions?</label>
                                                    <input
                                                        type="text"
                                                        style={{ maxWidth: '350px', display: 'block' }}
                                                        name="email"
                                                        id="item05"
                                                        placeholder="Email *"
                                                        ref={register({ required: '*Please enter your email*' })}
                                                    />
                                                    <div style={{ color: "red" }}>{errors.email && errors.email.message}</div>
                                                </div>
                                                : contact === "phone" ?
                                                    <div>
                                                        <label style={{ marginLeft: "10px" }} htmlFor="item03">What is the best Phone # to reach you at for any questions?</label>
                                                        <div className="col-8">
                                                            <NumberFormat
                                                                name="tel"
                                                                id="item03"
                                                                style={{ maxLength: '150px', display: 'block' }}
                                                                value={tel}
                                                                onChange={e => setTel(e.target.value)}
                                                                placeholder="(555) 555-5555 *"
                                                                format="(###) ###-####"
                                                                mask="_"
                                                                getInputRef={register({
                                                                    pattern: {
                                                                        value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                                                                        message: '*Invalid phone number*',
                                                                    },
                                                                })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.tel && errors.tel.message}</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label style={{ marginLeft: '-10px' }}>Do we have permission to leave a voicemail with details about your medication refill?</label><br />
                                                            <label style={{ display: 'inline-block', position: 'relative', marginLeft: '15px' }} htmlFor="voicemail1">Yes, that is ok
                                                                <input style={{ height: '20px', position: 'absolute', marginLeft: '-155px' }} type="radio" name="voicemail" id="voicemail1" value="Yes, that is ok" ref={register({ required: '*Please select an answer*' })} />
                                                            </label><br />
                                                            <label style={{ display: 'inline-block', marginLeft: '15px' }} htmlFor="voicemail2">No, please just say the message is for me and leave Redemption contact name and number.
                                                                <input style={{ height: '20px', position: 'absolute', right: '48%', top: '61%' }} type="radio" name="voicemail" id="voicemail2" value="No, please just say the message is for me and leave Redemption contact name and number." ref={register()} />
                                                            </label>
                                                            <div style={{ color: "red" }}>{errors.voicemail && errors.voicemail.message}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div style={{ justifyContent: 'center' }} className="row row--35 align-items-start">
                                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {/* End Modal 2 Area */}

            {/* Start Modal 3 Area */}
            <Modal show={open3} size='lg' onHide={handleClose}>
                <Modal.Body>
                    <h3 style={{ textAlign: 'center' }}>Links</h3>
                    <p>A list of outside links with relevant and useful information, just click any link you'd like to view to be redirected.</p><br />
                    <div className="row">
                        <div className="col-6">
                            <p style={{ fontSize: 18, marginBottom: '5px' }}><strong>Counseling Offices</strong></p>
                            <li>
                                <a href="https://gilbertcounselors.com/" target="_blank" rel="noopener noreferrer">Gilbert Counselors</a>
                            </li>
                            <li>
                                <a href="https://transitionscounselingandconsult.com/" target="_blank" rel="noopener noreferrer">Transitions Counseling</a>
                            </li>
                            <li>
                                <a href="https://alohacounseling.org/" target="_blank" rel="noopener noreferrer">Aloha Counseling</a>
                            </li>
                            <li>
                                <a href="https://www.totallifeaz.com/" target="_blank" rel="noopener noreferrer">Total Life Counseling</a>
                            </li>
                            <li>
                                <a href="https://www.sarvacenter.com/" target="_blank" rel="noopener noreferrer">Sarva Center</a>
                            </li>
                        </div>
                        <div className="col-6">
                            <p style={{ fontSize: 18, marginBottom: '5px' }}><strong>Hospitals/Treatment Centers</strong></p>
                            <li>
                                <a href="https://valleyhospital-phoenix.com/" target="_blank" rel="noopener noreferrer">Valley Hospital</a>
                            </li>
                            <li>
                                <a href="https://www.asapaz.com/?utm_source=gmb-mesa" target="_blank" rel="noopener noreferrer">Adolescent Substance Abuse Program</a>
                            </li>
                            <li>
                                <a href="https://coppersprings.com/" target="_blank" rel="noopener noreferrer">Copper Springs</a>
                            </li>
                            <li>
                                <a href="https://cornerstonehealingcenter.com/" target="_blank" rel="noopener noreferrer">Cornerstone Healing Center</a>
                            </li>
                        </div>
                        <div className="col-12">
                            <p style={{ fontSize: 18, marginTop: '30px', marginBottom: '5px' }}><strong>Additional Links</strong></p>
                            <li>
                                <a href="https://988lifeline.org/" target="_blank" rel="noopener noreferrer">988 Suicide & Crisis Lifeline</a>
                            </li>
                            <li>
                                <a href="https://www.valant.io/myio-help/#UserGuide" target="_blank" rel="noopener noreferrer">MYIO Patient Portal Help - Your Patient Experience Portal</a>
                            </li>
                            <li> <a href="https://www.intelligent.com/online-college-guide-for-students-with-mental-health-disorders/" target="_blank" rel="noopener noreferrer">College Planning Guide</a>
                            </li>
                            <li>
                                <a href="https://iamteenstrong.com/" target="_blank" rel="noopener noreferrer">I Am Teen Strong -  An online magazine that provides guidance and resources to teens and their parents.</a>
                            </li>
                            <li>
                                <a href="https://onenten.org/" target="_blank" rel="noopener noreferrer">one•n•ten - A nonprofit dedicated to serving and assisting LGBTQ+ youth.</a>
                            </li>
                            <li>
                                <a href="https://www.wingedhope.org/" target="_blank" rel="noopener noreferrer">Winged Hope - A nonprofit that supports victims of family violence.</a>
                            </li>
                            <li>
                                <a href="https://shieldsandstripes.org/" target="_blank" rel="noopener noreferrer">Shields & Stripes - A nonprofit that assists veterans and first responders with visible and invisible wounds.</a>
                            </li>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {/* End Modal 3 Area */}

            {/* Start Modal 4 Area */}
            <Modal show={open4} onClose={handleClose}>
                <Modal.Body>
                    <h3 style={{ textAlign: "center", margin: "auto" }}>Form Submitted Successfully!</h3><br />
                    <div style={{ textAlign: "center" }}>
                        You should receive a call from your pharmacy within 2-5 days to update you on the status of refilling your medication. You will only receive a call back from our staff if we have a question regarding your submission. <br /><br />
                        Thank you!
                        <hr />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button style={{ margin: '0 auto' }} className="rn-button-style--2 btn-solid" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {/* End Modal 4 Area */}

            {/* Start Modal 5 Area */}
            <Modal show={open5} size='lg' onHide={handleClose}>
                <Modal.Body>
                    <h3 style={{ textAlign: 'center' }}>Patient Forms</h3>
                    <p>Click on which form you want to view, then you can download and/or print if needed.</p><br />
                    <div className="row">
                        <div className="col-6">
                            <a href={ReleaseofInformation} target="_blank" rel="noopener noreferrer">Release of Information</a><br /><br />
                            <a href={ConsenttoTreatPatient} target="_blank" rel="noopener noreferrer">Consent to Treatment (Patient)</a><br /><br />
                            <a href={ConsenttoTreatGuardian} target="_blank" rel="noopener noreferrer">Consent to Treatment (Guardian)</a><br /><br />
                            <a href={LegalConsentforChildAdolescentClients} target="_blank" rel="noopener noreferrer">Legal Consent for Child-Adolescent Clients</a><br /><br />
                            <a href={PrivatePayAgreement} target="_blank" rel="noopener noreferrer">Private Pay Agreement</a><br /><br />
                        </div>
                        <div className="col-6">
                            <a href={NoticeofPrivacyPractices} target="_blank" rel="noopener noreferrer">Notice of Privacy Practices</a><br /><br />
                            <a href={NoticeofPrivacyPracticeswithAcknowledgement} target="_blank" rel="noopener noreferrer">Notice of Privacy Practices with Acknowledgement</a><br /><br />
                            <a href={CourtLegalPolicy} target="_blank" rel="noopener noreferrer">Notice of Court/Legal Services Policy</a><br /><br />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            {/* End Modal 5 Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>

    )
}

export default Testimonial

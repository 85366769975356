import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "800px",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore : {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    showLess : {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    iconHover : {
        "&:hover":{
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function CostOfTreatment() {
    const classes = useStyles();
    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText:<div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText:<div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 200, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        }
    }
    return (
        <div>
            <div className={classes.title}>
                •	Low cost infusions.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Redemption Psychiatry keeps costs low by being one of the largest private psychiatric practices in Arizona, performing possibly the largest volume of Ketamine infusions in the valley. Being one of the largest providers also allows us to keep the costs per infusion low.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Discount programs
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                We offer significant discounts to first responders, medical professionals, and military through our Community Heroes program. We also offer discounts to patients undergoing additional treatments with us using transcranial magnetic stimulation. In addition, patients receiving maintenance ketamine will get a discount.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Low risk commitment
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Unlike most Ketamine practices, commitment to the initial series of 6 infusions is not required at Redemption Psychiatry. Patients may qualify for the first infusion at no additional cost following their initial paid consultation, which may be covered by your insurance. Additional infusions are pay-as-you-go with no pressure to overextend your finances.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Payment plans are available
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Patients may qualify for a payment plan that will allow no interest payments to be taken out at regular intervals to make it easier to afford treatment.
              </div>
            </ReactTextCollapse>
        </div>
    );
}
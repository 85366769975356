import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DrugDanger from "../../../../../public/assets/images/Treatments/Drug_danger_and_dependence.png"


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "100%",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore: {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    showLess: {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    iconHover: {
        "&:hover": {
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function Addictive() {
    const classes = useStyles();
    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText: <div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText: <div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 200, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        },
    };
    const TEXT_COLLAPSE_OPTIONS_FAT = {
        collapse: false, // default state when component rendered
        collapseText: <div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText: <div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 500, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        },
    };
    return (
        <div>
            <div>
                <div className={classes.title}>
                    1. Ketamine’s reputation has been tarnished due to illicit use.
                </div>
                <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                    <div className={classes.description}>
                        This question often stems from recognizing “ketamine” as a street drug or “horse tranquilizer” that is referred to as “Special K,” “Vitamin K,” or simply “K.” When ketamine is abused recreationally as an illegal drug, individuals are self-administering the drug with no supervision and frequently taking high doses.  In this drug abuse scenario, a person can become addicted to ketamine.
                    </div>
                </ReactTextCollapse>
                <br /> <br />
                <div className={classes.title}>
                    2. Ketamine has low addictive potential compared to other substances.
                </div>
                <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS_FAT}>
                    <div className={classes.description}>
                        If you have ever smoked a cigarette, had an alcoholic beverage, used prescription painkillers, or even experimented with an illegal drug, you were exposed to some risk for addiction. However, not everyone who tries a potentially addictive substance will become dependent on it. The potential for dependence on any substance is strongly related to the circumstances surrounding its use such as quantity, frequency, setting, reason for using, and monitoring.
                        <br /><br />
                        <img style={{ display: "block", width: "100%", maxWidth: "800px", margin: "0 auto" }} src={DrugDanger} alt="drug addition levels chart" />
                        <br />
                        Ketamine has a much lower potential for dependence, which is why ketamine is more and more often used for pain in emergency situations, like setting a bone or reducing a dislocated joint, where we would alternatively use an addictive substance, like morphine.
                    </div>
                </ReactTextCollapse>
                <br /> <br />
                <div className={classes.title}>
                    3. Ketamine given by infusion is not an abused route of administration.
                </div>
                <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                    <div className={classes.description}>
                        Slowly infused, low-dose ketamine does not result in the sudden reaction that recreational users hope to experience when abusing ketamine. They take a high dose all at once, which can create a potentially addictive high. When a patient is given ketamine in a safe environment, strictly monitored by a healthcare provider, the addictive potential is not significant.
                    </div>
                </ReactTextCollapse>
            </div>
        </div>
    );
}
import React, { Component } from "react";

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <h2 className="title" style={{ textAlign: "center" }}>What people are saying about us...</h2>
                    <ul className="brand-style-2">
                        <li style={{ padding: '0px' }}>
                            <a href="https://g.co/kgs/8iKZev" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/Review1.png" alt="Logo Images" style={{ maxWidth: '500px', maxHeight: '300px' }} /></a>
                        </li>
                        <li style={{ padding: '0px' }}>
                            <a href="https://g.co/kgs/FrEjHR" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/Review2.png" alt="Logo Images" style={{ maxWidth: '500px', maxHeight: '300px' }} /></a>
                        </li>
                        <li style={{ padding: '0px' }}>
                            <a href="https://g.co/kgs/qpPDGK" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/Review3.png" alt="Logo Images" style={{ maxWidth: '500px', maxHeight: '300px' }} /></a>
                        </li>
                    </ul>
                    <ul className="brand-style-2">
                        <li style={{ padding: '0px' }}>
                            <a href="https://g.co/kgs/NnsrEU" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/Review4.png" alt="Logo Images" style={{ maxWidth: '500px', maxHeight: '300px' }} /></a>
                        </li>
                        <li style={{ padding: '0px' }}>
                            <a href="https://g.co/kgs/ybVww9" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/Review5.png" alt="Logo Images" style={{ maxWidth: '500px', maxHeight: '300px' }} /></a>
                        </li>
                    </ul>
                </div>
            </React.Fragment >
        )
    }
}
export default BrandTwo;
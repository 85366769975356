import React from "react";

const Button = (props) => {

   const clickHandler = () => {
      props.setURL(props.DisplayName);
      props.setActiveButton(props.id);
      props.executeScroll();
   };

   const activeStyle = (props) => {
      const baseStyle = {
         width: '150px',
         height: '200px',
         display: 'inline-block',
         color: 'black',
         margin: '5px',
         backgroundImage: `url(${props.PictureLink})`,
         backgroundSize: 'cover',
      };

      if (props.active) {
         return {
            ...baseStyle,
            border: '6px solid green',
         };
      }

      return {
         ...baseStyle,
         border: '2px solid black',
      };
   };

   // Function to render office names
   const renderOffices = () => {
      // Check if offices exist and map over them to create a list
      return props.Offices && props.Offices.map((office, index) => (
         <span key={index}>
            {office}{index < props.Offices.length - 1 ? ', ' : ''}
         </span>
      ));
   };

   return (
      <div className="col-sm-4 col-md-3 col-xl-2">
         <div
            style={activeStyle(props)}
            className="btn"
            id={props.id}
            value={props.DisplayName}
            onClick={clickHandler}>
         </div>
         <div style={{
            backgroundColor: 'rgb(127, 130, 141, 0.5)',
            border: '2px solid black',
            borderRadius: '20px',
            margin: 'auto',
            maxWidth: '200px',
            padding: '10px',
            textAlign: 'center'  // Center the text
         }}
            onClick={clickHandler}>
            <strong>{props.DisplayName}</strong> <br />
            {renderOffices()} {/* Call the function to render office names */}
         </div>
      </div>
   );
};

export default Button;

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TestimonialTwo = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Tabs>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p style={{fontWeight:'500', fontSize: '24px'}}>To provide the highest quality psychiatric care using the most advanced, evidence-based treatments available.</p>
                            </div>
                            <div className="author-info">
                                <h6><span style={{textTransform:'none'}}>We employ the newest medical technology for treatments and to provide our clients with the information and convenience expected in our fast-paced modern lives. This allows us to spend more appointment time relating to our clients instead of collecting and documenting routine information.</span></h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabList className="testimonial-thumb-wrapper">
                        <Tab>
                        </Tab>
                    </TabList>
                </Tabs>
            </div>
        </div>
    )
}
export default TestimonialTwo

import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiClock,} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--3" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient2">Riding The Storm: Looking After Your Mental Health During The Pandemic</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />July, 2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>As the COVID-19 pandemic sweeps the world, it’s spreading fear and <a href="https://www.washingtonpost.com/health/mental-health-experts-offer-counsel-on-staying-calm-during-coronavirus-pandemic/2020/03/17/a1a22af6-6701-11ea-b313-df458622c2cc_story.html" alt="source link">anxiety</a> across the population, particularly among healthcare providers and people with underlying health conditions.</p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-05.jpg" alt="Blog Images" />
                                        </div>
                                        <br /><br />
                                        <p className="mt--40">With social isolation, the crumbling world economy, and rising unemployment, many are experiencing intense psychological trauma. According to the <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6717708/" alt="source link">Kaiser Family Foundation</a> poll, nearly half of Americans report that the pandemic is harming their mental health. Additionally, the emergency hotline for emotionally distressed people <a href="https://www.washingtonpost.com/news/powerpost/paloma/the-health-202/2020/05/04/the-health-202-texts-to-federal-government-mental-health-hotline-up-roughly-1-000-percent/5eaae16c602ff15fb0021568/?itid=ap_paigewinfield%20cunningham&itid=lk_inline_manual_12" alt="source link">registered a 1000% increase</a> compared to last year.</p>
                                        <p>Federal experts and agencies warn that a wave of mental health problems is approaching. They also highlight a higher risk of substance abuse, depression, anxiety, PTSD, and suicide.</p>
                                        <p>Just as we took steps to prevent healthcare institutions from being overrun, we must brace for a mental health pandemic by providing telehealth services and mental health screenings.</p>
                                        <p>In this blog, we’ll discuss how individuals and society can cope with loneliness and anxiety while preserving their mental well-being:</p>
                                        <h3>Stay Informed But Set Limits For Social Media And News</h3>
                                        <p>It’s natural to feel anxious with the constant stream of news reports and updates – which is why you should limit news access to once or twice a day.</p>
                                        <p>Keep a realistic perspective of the situation; separate facts from fiction by referring to reliable sources. If the coronavirus coverage overwhelms you, ask a family member or friend to give you updates, so you don’t fall down the rabbit hole.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail" style={{textAlign: "center"}}>
                                                <img className="w-75" src="/assets/images/blog/blog-single-04.jpg" alt="BLog Images" />
                                            </div>
                                            <div className="content">
                                                <br /><br /><br />
                                                <h3>Practice Self-Care</h3>
                                                <p>When forced to stay at home, it’s easy to slip into habits like sleeping late, spending all day in bed, and unhealthy eating. Remember, looking after yourself is an essential part of mental health, so try to maintain a daily routine. </p>
                                                <ul className="list-style">
                                                    <li>Eat healthy</li>
                                                    <li>Maintain physical activity</li>
                                                    <li>Maintain a proper sleep schedule</li>
                                                    <li>Avoid Alcohol</li>
                                                    <li>Practice relaxation techniques</li>
                                                    <li>Allocate specific hours to work and rest</li>
                                                    <li>Even accomplishing simple tasks in a day can make a difference in the way you feel.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <h3>Reach Out To Others for Support</h3>
                                        <p className="mt--25 mt_sm--5">Keeping in touch with family and friends is crucial during these trying times. Talking through your concerns and acknowledging your feelings can help you find different ways of dealing with challenges.</p>
                                        <p className="mt--25 mt_sm--5">Receiving care and support from others brings a sense of stability and comfort. Try to focus on the positive in your life. Share positive stories of those who have recovered from the coronavirus or those who have helped their loved ones recover.</p>
                                        <h3>Reach Out For Professional Support</h3>
                                        <p className="mt--25 mt_sm--5">If things get difficult, and fear and anxiety become overwhelming, having a plan beforehand can help you get through.</p>
                                        <h5 className="title">Get immediate help in a crisis</h5>
                                        <ul className="list-style" style={{marginLeft: "50px"}}>
                                                    <li><a href="https://www.samhsa.gov/find-help/disaster-distress-helpline" alt="source link">Disaster Distress Helpline</a></li>
                                                    <li><a href="https://suicidepreventionlifeline.org/" alt="source link">National Suicide Prevention Lifeline</a></li>
                                                    <li><a href="https://www.thehotline.org/" alt="source link">National Domestic Violence Hotline</a></li>
                                                </ul>
                                        <p className="mt--25 mt_sm--5">If you’re currently seeing your primary psychiatric health care provider for Major Depressive Disorder (MDD), PTSD, Postpartum depression or suicidality, and the standard treatments don’t seem to be working, you may benefit from ketamine infusion therapy.</p>
                                        <p className="mt--25 mt_sm--5">Despite being an off-label treatment for mental disorders, <a href="https://www.biologicalpsychiatryjournal.com/article/S0006-3223(99)00230-9/fulltext" alt="source link">ketamine has shown</a> to produce robust and rapid effects within a few days of administration, reducing depressive symptoms and suicidal ideations. Ketamine Treatment for depression really works! Here’s <a href="https://rpsych.com/ketamine" alt="source link">what our patients have to say.</a></p>
                                        <p className="mt--25 mt_sm--5">Get in touch with our experts in Arizona at (480) 542-1212 to find out if ketamine infusion therapy is right for you. You can also visit one of our <a href="https://rpsych.com/contact" alt="source link">four ketamine clinics throughout the valley.</a></p>
                                        <br /><br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;
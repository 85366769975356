import React, { Component } from "react";

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <h2 className="title" style={{ textAlign: "center" }}>Our Partners</h2>
                    <ul className="brand-style-2">
                        <li>
                            <a href="https://gilbertcounselors.com/" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/brand-01.jpg" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://transitionscounselingandconsult.com/" target="_blank" rel="noopener noreferrer"><img src="https://s33929.pcdn.co/wp-content/uploads/sites/629/2020/05/Transitions-Logo-Color-e1641923928584-800x157.png" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://www.sarvacenter.com/" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/brand-04.png" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://www.asapaz.com/?utm_source=gmb-mesa" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/brand-07.webp" alt="Logo Images" /></a>
                        </li>
                    </ul>
                    <ul className="brand-style-2">
                        <li>
                            <a href="https://alohacounseling.org/" target="_blank" rel="noopener noreferrer"><img src="https://alohacounseling.org/wp-content/uploads/2021/10/Logo-2.png" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://pinnaclepsychology.com/" target="_blank" rel="noopener noreferrer"><img src="https://brightervisionclients.com/resize/66a7caa33a23ea39af765e7c/attachments/45e432d3-3229-4f33-9bdf-de78c3f7641e" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://valleyhospital-phoenix.com/" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/brand-02.png" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://coppersprings.com/" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/brand-05.png" alt="Logo Images" /></a>
                        </li>
                    </ul>
                    <ul className="brand-style-2">
                        <li>
                            <a href="https://cornerstonehealingcenter.com/" target="_blank" rel="noopener noreferrer"><img src="https://cornerstonehealingcenter.com/wp-content/uploads/cornerstone-logo-300x66-1-1-1.png" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://www.totallifeaz.com/" target="_blank" rel="noopener noreferrer"><img src="https://images.squarespace-cdn.com/content/v1/5f60401cea4962423a1df95e/1600204151813-XW7K9PFNDS3XF2LAC8M8/total-life-counseling-az-logo.png?format=1500w" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://meadowsbh.com/" target="_blank" rel="noopener noreferrer"><img src="/assets/images/brand/brand-09.png" alt="Logo Images" /></a>
                        </li>
                        <li>
                            <a href="https://www.charliehealth.com/" target="_blank" rel="noopener noreferrer"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt6A2bYbnPtsM8c3ZsfMA0RmgIqgF9pA-A6g&s" alt="Logo Images" /></a>
                        </li>
                    </ul>
                    <ul className="brand-style-2">
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            <a
                                href="https://www.psychologytoday.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Visit our Psychology Today profile"
                                style={{ display: 'inline-block' }}
                            >
                                <img
                                    src="/assets/images/icons/psychtoday.png"
                                    alt="Verified by Psychology Today"
                                    style={{ width: '200px', height: 'auto' }}
                                />
                            </a>
                        </div>
                    </ul>
                </div>
            </React.Fragment >
        )
    }
}
export default BrandTwo;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";



class CrisisLines extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='PrivacyPolicy' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--12" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title color-1">Crisis Lines</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Resource Area */}
                <div className="service-area pt--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Crisis Lines:</h2>
                                    <p><strong>National Suicide Crisis Hotline: </strong>1-800-273-TALK</p>
                                    <p><strong>Maricopa County - Crisis Response Network: </strong>1-800-631-1314, 602-222-9444</p>
                                    <p><strong>Empact Crisis: </strong>480-784-1500</p>
                                    <p><strong>Pima County Crisis Line:  </strong>1-800-796-6762</p>
                                    <p><strong>Graham, Greenlee, Santa Cruz, Cochise, Pinal, Gila, La Paz, 	&#38; Yuma County Crisis Line: </strong>1-866-495-6735</p>
                                    <p><strong>Apache, Coconino, Mojave, Navajo, and Yavapai County Crisis Line: </strong>1-877-756-4090</p>
                                    <p><strong>Gila River and Ak-Chin Indian Communities: </strong>1-800-259-3449</p>
                                    <br /><br />
                                    <h2>Psychiatric Urgent Care Facilities:</h2>
                                    <p><strong>Connections Arizona (UPC): </strong>602-416-7600</p>
                                    <p><strong>Recovery Innovations of Arizona: </strong>602-650-1212</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br /><br /><br /><br />
                {/* End Resource Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default CrisisLines;
import React, { Component } from "react";
// import ReactTextCollapse from "react-text-collapse";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiChevronUp } from "react-icons/fi";

// const ServiceListOne = [
//     {
//         icon: <FiBatteryCharging />,
//         title: 'PSYCHIATRIC EVALUATION',
//         description: 'Diagnostic Interview, Medical Evaluation (Labs and Imaging as appropriate) and referrals for therapy.'
//     },
//     {
//         icon: <FiCloudDrizzle />,
//         title: 'MEDICATION MANAGEMENT',
//         description: 'We provide followup appointments for evaluating and ensuring the continuing effectiveness of prescribed medications.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'GENETIC TESTING FOR DRUG EFFECTIVENESS',
//         description: 'We can administer genetic testing to determine how a patients genes may affect the way certain drugs will work for them.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'TELE-PSYCHIATRY',
//         description: 'Our psychiatric professionals conduct telemedicine to provide needed psychiatric services to rural and isolated populations.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'ADHD TESTING',
//         description: 'We use QbTest ®, an FDA approved system, to accurately evaluate the symptoms of ADHD for children and adults. This system combines motion-tracking analysis with a uniquely designed continuous performance task that takes about 20 minutes. Results are available immediately after the test is completed.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'TRANSCRANIAL MAGNETIC STIMULATION (TMS)',
//         description: 'TMS is an innovative new depression treatment now available at Redemption Psychiatry. TMS is for those patients for whom traditional treatment is ineffective or when the side effects of depression medication are too significant to continue treatment. Similarly to MRIs TMS uses a targeted, pulsed magnetic field to stimulate the brain. It is minimally invasive and the patient may drive to and from their appointment.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'KETAMINE INFUSION THERAPY',
//         description: 'Ketamine was recently found to be a valuable and highly effective treatment for depression, anxiety, PTSD, OCD, fibromyalgia and other pain syndromes. Research studies conducted by the National Institute of Mental Health (NIMH) as well as academia and industry show that ketamine produces a quick and robust response in patients shown to be unresponsive to other standard treatments.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'PHOENIX WEIGHT LOSS PROGRAM',
//         description: 'Redemption Psychiatry is now partnering with Dr. Gear of Phoenix Weight Loss to provide a child-centered obesity weight loss program. Dr. Gears program at Redemption Psychiatry will consist of group as well as individual meetings to support your childs weight loss. His program utilizes behavioral modification, diet, physical activity and nutraceuticals to promote significant and long-lasting weight loss.'
//     },
// ]

// const TEXT_COLLAPSE_OPTIONS = {
//     collapse: false, // default state when component rendered
//     collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
//     expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
//     minHeight: 0, // component height when closed
//     maxHeight: 200, // expanded to
//     color: "black",
//     textStyle: { // pass the css for the collapseText and expandText here
//         color: "#003C8F",
//         fontSize: "20px",
//         marginLeft: "33%",
//     }
// }
// const TEXT_COLLAPSE_OPTIONS2 = {
//     collapse: false, // default state when component rendered
//     collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Symptoms of Depression</h6><FiChevronDown /></div>, // text to show when collapsed
//     expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Hide Symptoms of Depression </h6><FiChevronUp /></div>, // text to show when expanded
//     minHeight: 0, // component height when closed
//     maxHeight: 500, // expanded to
//     color: "black",
//     textStyle: { // pass the css for the collapseText and expandText here
//         color: "#003C8F",
//         fontSize: "20px",
//         marginLeft: "22%",
//     }
// }
// const TEXT_COLLAPSE_OPTIONS3 = {
//     collapse: false, // default state when component rendered
//     collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
//     expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
//     minHeight: 0, // component height when closed
//     maxHeight: 300, // expanded to
//     color: "black",
//     textStyle: { // pass the css for the collapseText and expandText here
//         color: "#003C8F",
//         fontSize: "20px",
//         marginLeft: "33%",
//     }
// }
// const TEXT_COLLAPSE_OPTIONS4 = {
//     collapse: false, // default state when component rendered
//     collapseText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show More </h6><FiChevronDown /></div>, // text to show when collapsed
//     expandText: <div className="iconHover"><h6 style={{ display: "inline", color: "#4472C4" }}> Show Less </h6><FiChevronUp /></div>, // text to show when expanded
//     minHeight: 0, // component height when closed
//     maxHeight: 350, // expanded to
//     color: "black",
//     textStyle: { // pass the css for the collapseText and expandText here
//         color: "#003C8F",
//         fontSize: "20px",
//         marginLeft: "33%",
//     }
// }

class TMSinfo extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Services Provided' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title color-1">All Services Provided</h2>
                                    <p>This is a list of our treatment options.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div className="service-details-inner"> */}
                                <div className="inner">
                                    {/* Start Service Area */}
                                    {/* <div className="service-area ptb--30 bg_color--1">
                                        <div className="container">
                                            <div className="row service-one-wrapper">
                                                {ServiceListOne.map((val, i) => (
                                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                        <a className="text-center" href="/service-details">
                                                            <div className="service service__style--2">
                                                                <div className="icon">
                                                                    {val.icon}
                                                                </div>
                                                                <div className="content">
                                                                    <h3 className="title">{val.title}</h3>
                                                                    <p>{val.description}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* End Service Area */}

                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>PSYCHIATRIC EVALUATION</h3>
                                                <p>Diagnostic Interview, Medical Evaluation (Labs and Imaging as appropriate) and referrals for therapy.
                                                    <br /><br />
                                                    {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                                                        For depression, this usually consists of 20-minute sessions 5 days a week for 6 weeks. TMS patients often notice a significant reduction in symptoms and experience improvement in their quality of life, reduction in medication use, fewer doctor’s visits, and reduced risk of hospitalizations and suicide.
                                                    </ReactTextCollapse> */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>MEDICATION MANAGEMENT</h3>
                                                <p>We provide followup appointments for evaluating and ensuring the continuing effectiveness of prescribed medications.</p><br /><br />
                                                {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS2}>
                                                    <h6>Symptoms of depression may include:</h6>
                                                    <ul className="liststyle">
                                                        <li>Feeling sad, hopeless, or depressed</li>
                                                        <li>Difficulty concentrating, thinking, or making decisions</li>
                                                        <li>Loss of interest or pleasure in activities once enjoyed</li>
                                                        <li>Changes in appetite, increased or decreased</li>
                                                        <li>Lack of energy or easily tired</li>
                                                        <li>Trouble sleeping or sleeping too much</li>
                                                        <li>Excessive feelings of guilt or worthlessness</li>
                                                        <li>Restlessness or sluggishness</li>
                                                        <li>Trouble concentrating, remembering details, and making decisions</li>
                                                        <li>Digestive problems that don't get better, even with treatment</li>
                                                        <li>Thoughts of death or suicide</li>
                                                    </ul>
                                                </ReactTextCollapse> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--100 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-05.png" alt="Service Images" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>ADHD TESTING</h3>
                                                <p>We use QbTest ®, an FDA approved system, to accurately evaluate the symptoms of ADHD for children and adults. This system combines motion-tracking analysis with a uniquely designed continuous performance task that takes about 20 minutes. Results are available immediately after the test is completed.
                                                    <br /><br />
                                                    {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS3}>
                                                        Overall, TMS is well tolerated meaning that it is very uncommon that side effects are severe enough to cause a patient to discontinue TMS treatment. TMS can be used in conjunction with medication and therapy and is an effective complementary treatment. Because TMS does not produce changes outside the treatment area in the brain, it does not cause systemic side effects, and consequently, it is safe for women who are pregnant or breastfeeding.
                                                    </ReactTextCollapse> */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>TMS - TRANSCRANIAL MAGNETIC STIMULATION</h3>
                                                <p>TMS is an innovative new depression treatment now available at Redemption Psychiatry. TMS is for those patients for whom traditional treatment is ineffective or when the side effects of depression medication are too significant to continue treatment. Similarly to MRIs, TMS uses a targeted, pulsed magnetic field to stimulate the brain. It is minimally invasive and the patient may drive to and from their appointment.</p>
                                                <br />
                                                <div style={{ textAlign: 'center', color: 'blue', fontSize: '20px' }}>
                                                    <a href="https://rpsychtms.com" target="_blank" rel="noopener noreferrer">Visit our TMS Website</a>
                                                </div>
                                                {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS4}>
                                                    <p>For excitatory stimulation, increased firing has been shown to trigger the release of Brain Derived Neurotrophic Growth Factor (BDNF) and increase electrical activity in the areas stimulated. This in turn causes excitation in related brain regions connected to the area stimulated. Research has shown increased blood flow, oxygen use, and glucose metabolism at the stimulated areas as well as increased neuronal connections and cellular growth. In this way, TMS is a lot like rehab or exercise for the brain, but instead of getting bigger, stronger muscles, you get active, well-connected neurons.</p>
                                                </ReactTextCollapse> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-06.png" alt="Service Images" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-07.png" alt="Service Images" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>KETAMINE INFUSION THERAPY</h3>
                                                <p>Ketamine was recently found to be a valuable and highly effective treatment for depression, anxiety, PTSD, OCD, fibromyalgia and other pain syndromes. Research studies conducted by the National Institute of Mental Health (NIMH) as well as academia and industry show that ketamine produces a quick and robust response in patients shown to be unresponsive to other standard treatments.
                                                </p>
                                                <br />
                                                <div style={{ textAlign: 'center', color: 'blue', fontSize: '20px' }}>
                                                    <a href="https://rpsych.com/ketamine" target="_blank" rel="noopener noreferrer">Visit our Infusions Website</a>
                                                </div>
                                                {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS3}>
                                                        Overall, TMS is well tolerated meaning that it is very uncommon that side effects are severe enough to cause a patient to discontinue TMS treatment. TMS can be used in conjunction with medication and therapy and is an effective complementary treatment. Because TMS does not produce changes outside the treatment area in the brain, it does not cause systemic side effects, and consequently, it is safe for women who are pregnant or breastfeeding.
                                                    </ReactTextCollapse> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>TELE-PSYCHIATRY</h3>
                                                <p>Our psychiatric professionals conduct telemedicine to provide needed psychiatric services to rural and isolated populations.</p>
                                                <br />
                                                {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS4}>
                                                    <p>For excitatory stimulation, increased firing has been shown to trigger the release of Brain Derived Neurotrophic Growth Factor (BDNF) and increase electrical activity in the areas stimulated. This in turn causes excitation in related brain regions connected to the area stimulated. Research has shown increased blood flow, oxygen use, and glucose metabolism at the stimulated areas as well as increased neuronal connections and cellular growth. In this way, TMS is a lot like rehab or exercise for the brain, but instead of getting bigger, stronger muscles, you get active, well-connected neurons.</p>
                                                </ReactTextCollapse> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-04.png" alt="Service Images" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}
                                    
                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-03.png" alt="Service Images" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h3 style={{ textAlign: "center", fontStyle: "bold", fontSize: "2rem" }}>GENETIC TESTING FOR DRUG EFFECTIVENESS</h3>
                                                <p>We can administer genetic testing to determine how a patient's genes may affect the way certain drugs will work for them.
                                                    <br /><br />
                                                    {/* <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS3}>
                                                        Overall, TMS is well tolerated meaning that it is very uncommon that side effects are severe enough to cause a patient to discontinue TMS treatment. TMS can be used in conjunction with medication and therapy and is an effective complementary treatment. Because TMS does not produce changes outside the treatment area in the brain, it does not cause systemic side effects, and consequently, it is safe for women who are pregnant or breastfeeding.
                                                    </ReactTextCollapse> */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment >
        )
    }
}
export default TMSinfo;
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-scroll';

const StickyHeader = () => {
  return (
    <Navbar bg="light" expand="lg" className="sticky-top justify-content-center" style={stickyHeaderStyle}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
        <Nav>
          <Link activeClass="active" to="benefits_and_efficacy" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>Benefits and Efficacy</Nav.Link></Link>
          <Link activeClass="active" to="services" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>Services</Nav.Link></Link>
          <Link activeClass="active" to="patient_testimonials" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>Patient Testimonials</Nav.Link></Link>
          <Link activeClass="active" to="safety_and_side_effects" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>Safety & Side Effects</Nav.Link></Link>
          <Link activeClass="active" to="treatment_process" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>Treatment Process</Nav.Link></Link>
          <Link activeClass="active" to="pricing" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>Pricing</Nav.Link></Link>
          <Link activeClass="active" to="faqs" spy={true} smooth={true} duration={500}><Nav.Link as="a" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>FAQs</Nav.Link></Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const stickyHeaderStyle = {
  width: '100%',
  backgroundColor: 'white',
  zIndex: 1000,
  transition: 'all 0.25s',
};

export default StickyHeader;

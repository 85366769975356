import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    maxWidth: "800px",
  },
  textCenter: {
    textAlign: "center"
  },
  buttonMargin: {
    margin: "5px",
  }
}));

export default function SimplePopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = event => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClick4 = event => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClick5 = event => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);
  const id = open ? 'simple-popover' : undefined;
  const id2 = open ? 'simple-popover' : undefined;
  const id3 = open ? 'simple-popover' : undefined;
  const id4 = open ? 'simple-popover' : undefined;
  const id5 = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className={classes.textCenter}>
        <Button className={classes.buttonMargin} aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        IV Administration
      </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}> IV route is preferred. IV Ketamine absorption is 100%, it does not get broken down by the liver before reaching the brain, and it allows for greater control over the treatment. In short, IV the preferred method for Ketamine administration because it is the best. Regulating the rate of administration is essential. IV is also the only route of administration that allows for control over the delivery of the treatment. This means that the rate of infusion can be slowed down or sped up to optimize benefits and minimize side effects. It also means that an infusion can be stopped at any time. For example, if a patient has a significant increase in blood pressure, the IV infusion can be slowed and even stopped to prevent it from reaching unsafe levels. All other routes of administration are delivered all at once and cannot be stopped, so this situation would necessitate emergency services to reduce an unsafe rise in blood pressure. With IV access, we can also administer other medication that works immediately to control for other side effects. For example, with other routes of administration, nausea with vomiting occurs about 30% of the time, and without IV access, oral medications that would prevent vomiting cannot act fast enough and may be impossible to administer due to the nausea, but IV nausea treatment works immediately.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id2} variant="contained" color="primary" onClick={handleClick2}>
        Oral Administration
      </Button>
        <Popover
          id={id2}
          open={open2}
          anchorEl={anchorEl2}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}> Oral administration is broken down by the liver. When ketamine is taken orally, it is not broken down by the stomach acid, but rather it is absorbed in the gut unchanged. From there, it is delivered straight to the liver where it is immediately broken down into smaller particles called metabolites. Some of these metabolites can increase the risk of hallucinations and elevated blood pressure. This means much less medicine is reaching the brain because only about 20% of Ketamine passes through the liver into the bloodstream unchanged, and the risk of side effects is higher because the metabolites are all entering the bloodstream at once. So oral administration is not recommended.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id3} variant="contained" color="primary" onClick={handleClick3}>
        Nasal Administration
      </Button>
        <Popover
          id={id3}
          open={open3}
          anchorEl={anchorEl3}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}>Nasal administration is mostly swallowed and difficult to use. Intranasal sprays of ketamine can bypass some of the liver breakdown since it gets absorbed through the nasal mucosa into the bloodstream, however most of the dose given intranasally is swallowed and follows the same route as oral dosing. Intranasal sprays also depend on patients self-administering the treatment under supervision in specific way that must be performed precisely for the best results. This leads to inconsistent dosing, lower absorption, and inadvertently wasting the treatment.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id4} variant="contained" color="primary" onClick={handleClick4}>
        Muscle Injection
      </Button>
        <Popover
          id={id4}
          open={open4}
          anchorEl={anchorEl4}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}>Injections of ketamine are too intense. Intramuscular Ketamine is given as a shot all at once. Absorption is not the issue since the amount of Ketamine reaching the brain is almost equivalent to IV at 80%. However, the level of ketamine is not controlled, and the risk of side effects, especially hallucinations, is much higher than IV administration.</Typography>
        </Popover>
        <Button className={classes.buttonMargin} aria-describedby={id5} variant="contained" color="primary" onClick={handleClick5}>
        Other Routes
      </Button>
        <Popover
          id={id5}
          open={open5}
          anchorEl={anchorEl5}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}>Other routes require more treatments than IV. Oral, intramuscular, and intranasal ketamine treatment all need much more frequent dosing over a longer period of time to see improvement. This is in stark contrast to the rapid effects, less frequent dosing, and shorter treatment course seen with IV treatments. IV Ketamine’s effects on the brain are apparent in less than five minutes but take about 30 minutes when taken orally or intranasally.</Typography>
        </Popover>
      </div>
      <div className={classes.textCenter}>
        <Typography>
          Tap or click one of the buttons above to learn more!
        </Typography>
      </div>
    </div>
  );
}
import _objectSpread from "/workspace/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { container, title } from "../../material-kit-react.js";

// import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
// import imageTeam from "assets/img/romello-williams-P8VMwYFY-Es-unsplash.jpg";
// import imageTreatments from "assets/img/landing-bg-clouds.jpeg";
// import imageTestimonials from "assets/img/landing-bg-clouds.jpeg";

var profilePageStyle = {
  container: container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "260px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  profilePrice: {
    textAlign: "center",
    "& img": {
      maxWidth: "200px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -35%, 0)"
    }
  },
  profileContact: {
    textAlign: "center",
    "& img": {
      maxWidth: "200px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -220%, 0)"
    }
  },
  // backgroundTeam: {
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   borderRadius: "6px",
  //   backgroundImage: "url(" + imageTeam + ")",
  // },
  // backgroundTreatments: {
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   borderRadius: "6px",
  //   backgroundImage: "url(" + imageTreatments + ")",
  // },
  // backgroundTestimonials: {
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   borderRadius: "6px",
  //   width: "100%",
  //   backgroundImage: "url(" + imageTestimonials + ")",
  // },
  imgTab: {
    width: "100%",
    height: "100%",
    maxWidth: "500px",
    maxHeight: "500px",
    minWidth: "50px",
    minHeight: "50px",
    position: "block"
  },
  description: {
    margin: "0 auto",
    color: "#999",
    textAlign: "center !important"
  },
  descriptionBlack: {
    margin: "0 auto",
    color: "black",
    textAlign: "center !important",
    fontWeight: "400"
  },
  name: {
    marginTop: "-180px"
  },
  nameTestimonial: {
    marginTop: "-100px"
  },
  namePrice: {
    marginTop: "-130px"
  },
  nameContact: {
    marginTop: "-80px",
    textAlign: "center"
  },
  nameNoPic: {
    marginTop: "-20px"
  },
  // ...imagesStyle,
  // main: {
  //   background: "#FFFFFF",
  //   position: "relative",
  //   zIndex: "3"
  // },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: _objectSpread(_objectSpread({}, title), {}, {
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    marginBottom: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  }),
  titleFrost: _objectSpread(_objectSpread({}, title), {}, {
    marginBottom: "0",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "25px !important",
    backdropFilter: "blur(10px)"
  }),
  titleContact: _objectSpread(_objectSpread({}, title), {}, {
    position: "relative",
    marginTop: "0px",
    marginBottom: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  }),
  titleContactFrost: _objectSpread(_objectSpread({}, title), {}, {
    position: "relative",
    marginTop: "0px",
    marginBottom: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "left",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "25px !important",
    backdropFilter: "blur(10px)"
  }),
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  frost: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "25px !important",
    backdropFilter: "blur(10px)",
    textAlign: "center"
  },
  frostDark: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "25px !important",
    backdropFilter: "blur(10px)",
    textAlign: "center",
    color: "black"
  },
  MapContainer: {
    height: "700px"
  }
};
export default profilePageStyle;
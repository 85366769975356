import React from "react";

const Button = props => {
   return (
      <div>
         {props.currentProvider.map(provider => <div>
            <div className="row">
               <div className="col-12">
                  <div style={{
                     width: '150px',
                     height: '200px',
                     display: 'inline-block',
                     textAlign: 'right',
                     color: 'black',
                     margin: '5px',
                     backgroundImage: `url(${provider.PictureLink})`,
                     backgroundSize: 'cover',
                     borderRadius: '50% 50% 50% 50%',
                     boxShadow: 'box-shadow: 0 0 15px 10px #141414'
                  }}></div>
               </div>
               <div className="col-12">
                  <div style={{ fontWeight: '600', fontSize: '42px', fontFamily: 'Poppins', lineHeight: '1' }}>{provider.DisplayName}</div>
                  {provider.CurrentlyAcceptingPatients ?
                     <div style={{ fontWeight: '300', fontSize: '20px', fontFamily: 'Poppins', color: 'green' }}>Currently Accepting New Patients</div>
                     :
                     <div style={{ fontWeight: '300', fontSize: '20px', fontFamily: 'Poppins', color: 'red' }}>Not Accepting New Patients at this time</div>
                  }
                  <p style={{ fontSize: '16px' }}>{provider.Bio1}</p>
                  <p style={{ fontSize: '16px' }}>{provider.Bio2}</p>
                  <p style={{ fontSize: '16px' }}>{provider.Bio3}</p>
               </div>
            </div>
         </div>)}
      </div>
   )
}

export default Button
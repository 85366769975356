import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                <title>{this.props.pageTitle} || Redemption Psychiatry</title>
                    <meta name="keywords" content="TMS, transcranial magnetic stimulation, Ketamine, Infusions, MDD, Anxiety, Depression, PTSD, OCD, ADD, ADD/ADHD, ADHD, Ketamine Assisted Therapy, Psychiatrist, Psychiatry, Medical Care, Psych, Medication, Healthcare, Mental Health, Treatment, Child Health, Adolescent Health, Gilbert, Chandler, Phoenix, Mesa, Arizona" />
                    <meta property="og:title" content={this.props.pageTitle + " || Redemption Psychiatry"}></meta>
                    <meta property="og:description" content="Redemption Psychiatry is an outpatient psychiatric group, founded by Jason Friday, MD. We specialize in TRD (Treatment Resistant Depression) and use a variety of non-medication treatments that really work! We accept nearly all commercial insurance plans but are not a Medicare or AHCCCS provider. We are one of the most affordable providers of IV Ketamine Infusion Therapy. We are the only TMS (Transcranial Magnetic Stimulation) providers that use laser and image guidance for accurate coil placement and we offer additional enhancements to TMS treatment at no cost. " data-rh="true"></meta>
                    <meta property="og:url" content="https://www.rpsych.com/"></meta>
                    <meta property="og:site_name" content="Redemption Psychiatry || Arizona" data-rh="true"></meta>
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" data-rh="true"></meta>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;

import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class Accordion01 extends Component {
    render() {
        return (
            <Accordion className="accodion-style--1" preExpanded={'0'} allowZeroExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3 style={{ fontSize: '35px' }}>New Patients</h3>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Accordion allowZeroExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is the difference between psychiatrists, psychologists, and other therapists?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Psychiatrists are medical doctors (MD or DO) who completed four years of residency in psychiatry and can prescribe medications. Because of their medical training, Psychiatrists understand the complex interaction of psychiatric conditions with other medical conditions and medications, both psychiatric and medical. All psychiatrists receive broad training in various kinds of psychotherapy, but due to pressures from insurance companies, managed care has relegated psychiatrists to focus primarily on medication management. Notwithstanding, psychiatrists use psychotherapy techniques in their practices to augment treatment during appointments. Psychiatrists typically charge higher rates than other sorts of mental health providers, this is due in part to the highly competitive nature of medical school admission and length/expense of post-graduate training.</p>
                                    <p>Psychologists hold doctorate-level degrees (either PhD or PsyD) but they do not have a medical degree, and in most states cannot prescribe medication. Psychologists have more training in psychotherapeutic techniques and most have specific training in the administration of psychological testing, such as IQ and achievement tests. Psychologists fees are typically slightly lower than psychiatrists, but higher than other sorts of therapists. Psychologists and Psychiatrists often work together to compliment diagnosis and treatment.</p>
                                    <p>Other professionals who provide therapy include: Licensed Clinical Social Workers (LCSW), Licensed Clinical Professional Counselors (LCPC), Nurse Practitioners (NP), Physician's Assistants (PA), Educators (M.Ed), clergy, guidance counselors, "life coaches", and others. The training for these professions is shorter and more specific than psychiatrists and psychologists, so their fees are often lower but they are often more skilled in their area of expertise than the doctors. For this reason, doctors will often refer therapy to a professional therapist.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Why should I choose to see a psychiatrist, rather than another sort of mental health professional?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Maybe you shouldn't. Psychiatrists are often harder to find and more expensive than other mental health professionals. However, a psychiatrist is often the best choice if you think you may need medications, or want an opinion about medications. You may also feel that a broad medical background is helpful in understanding how the mind works. This is often useful when there are coexisting medical conditions or medications that effect body and mind. Some people choose to see a psychiatrist for their medications, and a different practitioner for psychotherapy and that is fine. The success of psychotherapy depends far more on the relationship between the patient and the therapist than it does on the therapist's degree, training, or even experience. Obviously, a patient must consider affordability, availability, geographic proximity, and other variables in their choice of therapist, but the most important factor is whether the patient and therapist are able to develop a strong therapeutic relationship.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Should I have a psychiatrist prescribe my medications, rather than my family doctor?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Maybe not. Often problems with mild depression or anxiety can be treated with simple medication management and a primary care doctor may be very comfortable managing this. Many people find this effective and satisfying. However, family doctors treat psychiatric disorders the same way they treat other illnesses where they look at a checklist of symptoms and prescribe a treatment, but this may be inadequate. Patients often find that short, intermittent follow-up visits prove ineffective. Psychiatrists are able to integrate the complex mental and social factors that impact the illness by taking a biological, psychological and social approach. Their expertise with the medication allows them to provide a more tailored approach to fit the patient's needs. Once you've decided to have a psychiatrist prescribe your medication, you should notify your primary doctor so that they are no longer prescribing any psychiatric medication as this can cause significant risk to the patient's health and safety.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Does Redemption Psychiatry provide therapy?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>During a visit for psychiatric medication management, there is some psychotherapy involved, and your provider may discuss coping skills and other therapeutic techniques with you. However, we do not provide comprehensive talk therapy sessions. Instead, we maintain a referral network of outside therapy practices to ensure our patients receive well-rounded care. We believe coordination of care is incredibly important to a successful treatment plan.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I’ve tried several medications in the past, what other options are there?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>At Redemption Psychiatry, we offer advanced treatment options for patients who are not finding success with traditional medication management. We offer two primary advanced treatments: the first of which is Transcranial Magnetic Stimulation (TMS). TMS is a non-invasive, highly effective approach to treating depression. The second is Ketamine Infusion Therapy, which has been proven to greatly improve symptoms of chronic depression, anxiety, PTSD, and more. You can learn more about Ketamine Infusion Therapy <a href='https://rpsych.com/ketamine'>here</a> and learn more about TMS <a href='https://rpsychtms.com/'>here</a>.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What ages are treated at Redemption Psychiatry?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p><ul style={{listStylePosition: 'inside'}}>
                                        <li>Redemption Psychiatry providers generally treat patients ages 5-64.</li>
                                        <li>Children under 5 with behavioral or psychiatric symptoms are often better off seeing a developmental pediatrician.</li>
                                        <li>Patients 65 and older with psychiatric disorders fall into a subspecialty of geriatric psychiatry. Psychiatric providers who see adults can see these ages.</li>
                                        <li>A parent or legal guardian of a minor, who has medical decision-making authority, may schedule an appointment, and must accompany the minor.
                                            <ul>
                                                <li>For divorced parents that have joint medical decision making, either parent may present the minor for treatment. It is the responsibility of the parents to agree on treatment options. The provider will make determinations and recommendations based on medical evidence.</li>
                                                <li>16 and 17 year old patients must attend their intake appointment with a legal guardian. They may drive themselves to follow-up appointments, however, changes in the treatment plan cannot be made without the approval of their legal guardian.</li>
                                            </ul>
                                        </li>
                                    </ul></p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What if my health insurance plan is not contracted with Redemption Psychiatry?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>We provide superbills to patients with non-contracted insurance companies containing all the necessary information for them to submit claims to their insurance company for reimbursement. Many plans will reimburse 50% or more for out-of-network providers. We encourage you to call your insurance company to find out the limits of your policy. Payment is due prior to each appointment and may be in the form of cash, PayPal®, debit card, or credit card. We do not accept checks.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Does Redemption Psychiatry offer telemedicine visits?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Yes, we do offer telemedicine appointments. However, new patients should be seen in person for their first visit to get the appropriate measurements and vital signs, and to ensure that technical issues are not a barrier to providing a comprehensive assessment. Additionally, in accordance with US Law 21 U.S.C. 829(e), patients being prescribed controlled substances are required to be seen in-office initially and a minimum of once every 24 months thereafter. Patients may also be instructed to come into the office at their provider’s discretion. If you have concerns about this policy, please contact your provider’s office location.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What if Redemption Psychiatry is not in-network with my health insurance plan?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>While we are contracted with most major commercial insurance plans, we do offer options for patients with out-of-network plans. We can provide superbills containing all the necessary information for patients to submit claims to their insurance company for reimbursement. Many plans will reimburse 50% or more for out-of-network providers. Our self-pay rates are $350 for new patient appointments and $200 for follow-up appointments. Payment is due prior to each appointment and may be in the form of cash, debit card, credit card, or HSA card. We do not accept checks. We are not enrolled with Medicare or Medicaid, however, there are some commercial replacement plans associated with these which we can accept.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Does Redemption Psychiatry treat patients who live outside of Arizona?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Patients must be permanent or temporary residents of Arizona to be treated by providers at Redemption Psychiatry. Patients who are visiting or only live here part of the year may not be able to establish care with our office.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Can I schedule an appointment on behalf of my spouse/family member/friend?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>To schedule an appointment on behalf of a patient who is over 18 years old, the patient must provide authorization. This is done to protect patient privacy and to prevent appointments from being scheduled without the patient’s consent, which could result in issues for all parties. Adults with a Guardian or Conservatorship can have appointments scheduled on their behalf once we are provided the appropriate legal documentation.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3 style={{ fontSize: '35px' }}>Existing Patients</h3>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Accordion allowZeroExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How do I request a refill?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Medication refills are sent in during appointments, so patients are advised to make and keep regular follow-up visits to avoid running out. However, under some circumstances, refills can be issued between appointments. You can request a refill by calling your provider’s office or by going to <a href='https://rpsych.com/currentpatients'>https://rpsych.com/currentpatients</a> and selecting “Medication Refills”. Refill requests take between 2-5 business days to be addressed.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I haven’t been seen in a while, can I schedule an appointment as a current patient?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>If it has been 13+ months since your last visit, staff will have to submit a Reestablishment of Care (ROC) request. This is done to ensure that the provider is able to continue providing treatment after that period of time. Our staff will contact you to discuss next steps once your request is approved or denied. ROC requests may take up to 14 business days to be completed. If it has been 3+ years since your last visit, you will be scheduled for a new patient appointment, also known as an Intake appointment. This is due to the significant changes that can occur in that length of time.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Can I see a different provider than my current one?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>In most cases, yes. We understand that some circumstances may lead to a patient no longer seeing their current provider. In that situation, we will facilitate a Transfer of Care (TOC). When initiating a TOC, staff will ask you a few questions and then connect with providers that meet your preferences to see if they are able to take over your care. Our staff will contact you to discuss next steps once your request is approved or denied. TOC requests may take up to 14 business days to be completed.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Am I required to see my provider in person?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>We do require that patients come in office if it is their first time seeing the provider or if they are reestablishing care. This is to provide a more comprehensive assessment and ensure we input important measurements such as height, weight, blood pressure, etc. as accurately as possible. Additionally, in accordance with US Law 21 U.S.C. 829(e), patients being prescribed controlled substances are required to be seen in-office at least once every 24 months. Patients may also be instructed to come into the office at their provider’s discretion. If you have concerns about this policy, please contact your provider’s office to discuss your options.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What happens if I miss an appointment?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Our missed appointment policy (which is included in our Consent to Treat document signed by all new patients) indicates that a missed appointment will result in a $75 fee. Missing three appointments consecutively will likely result in the patient being discharged from the care of Redemption Psychiatry. If you have concerns about this policy, please contact your provider’s office for more information.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I am moving out of Arizona, can I still be treated by my provider at Redemption Psychiatry?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Patients are required to be permanent residents of Arizona in order to be treated by a provider at Redemption Psychiatry. Please notify your provider if you plan to move out of state so appropriate arrangements can be made.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Can I contact my provider directly in-between appointments?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>All direct contact with a provider should be done during a scheduled appointment. Outside of appointments, our support staff can assist with any questions or requests that you may have for your provider. Your provider is assigned both a nurse and a CCS II (an assistant). Our nurses receive medication questions, requests, concerns, etc. and communicate with your provider on your behalf to come to a resolution. The CCS IIs handle requests for paperwork and other administrative tasks, and will collaborate with your provider to determine next steps and complete the requests. We delegate these tasks to maintain a streamlined process for requests and to allow our providers to focus on providing the best care possible. When it is needed, staff will schedule you for an appointment to see your provider.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How does Redemption Psychiatry protect my privacy?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>We have posted our Notice of Privacy Practices, which are in keeping with the Health Insurance Portability and Accountability Act (HIPAA). Please review this document to see how we may use your health information. We will not release your information to any unauthorized entities without your written consent. Click on our "Patient Forms" link to see this notice. The Privacy Policy has a link at the bottom of every page on our website.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3 style={{ fontSize: '35px' }}>FMLA &amp; Disability</h3>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Accordion allowZeroExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is FMLA?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>FMLA is Family Medical Leave Act of 1993. The FMLA permits eligible employees to take up to 12 weeks of unpaid, job-protected leave in a 12-month period. Not all employers are subject to FMLA rules. The employer must be in the public sector, public or private school, or a private sector employer with more than 50 employees in a 75-mile radius.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What are the eligibility requirements for FMLA?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Requirements include the following:</p>
                                    <p><ul style={{listStylePosition: 'inside'}}>
                                        <li>Employee has worked for a covered employer for at least 12 months.</li>
                                        <li>Employee has worked at least 1250 hours during the 12-month period immediately preceding the leave (specific occupations may have different requirements)</li>
                                        <li>Employee or their spouse, child, or parent must have a qualifying serious health condition which makes the employee unable to perform essential function(s) of their job.</li>
                                    </ul></p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Can you use FMLA for mental health?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>If you are eligible, FMLA can be used for leave related to qualifying mental health conditions. Some ways FMLA may be used: adjusting to med changes, attending appointments, receiving in-office treatments like Ketamine Infusion Therapy or TMS, to recover from a flare-up or triggering event, and other qualifying reasons. Not all conditions qualify for FMLA. You should return to work as soon as you are able to.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I believe I may qualify for FMLA, what are the next steps?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Contact your employer and/or HR department for information about their process. Then, you should notify your mental health provider that you are planning to take FMLA leave, preferably during a scheduled appointment with them. You must ask your provider if they agree that your condition is disabling or requires time off of work. If they agree and are willing to support your claim, you must complete your portion of any and all paperwork provided by your employer. It can then be faxed securely, e-mailed, or physically brought into Redemption Psychiatry. It is recommended that paperwork be completed during a scheduled appointment, but paperwork that cannot be completed during an appointment is subject to a fee of $20 per page completed. We may contact you regarding some questions on the forms if paperwork is not filled out in an appointment.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is Short-Term Disability?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Short-Term Disability (STD) is an income-replacement benefit that provides a percentage of pre-disability earnings when a person is temporarily unable to work due to an illness or injury (including qualifying mental health conditions). Employees must be covered by a Short-Term Disability insurance policy in order to use this benefit. Benefits vary by employer, so you should always check with your Human Resources department to verify coverage and benefits.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I feel I may qualify for Short-Term Disability (STD), what do I do next?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>If you feel you may qualify for STD, you must initiate a claim through your employer. The STD firm will contact your provider and request copies of your medical records. Your provider is only able to present (to the STD company) the relevant facts regarding your treatment. The STD company will make the final determination of benefits, not the provider.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is there anything that can help my STD claim get approved?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Patients who are actively participating in their care and being compliant with all provider recommendations are more likely to be approved. The provider’s job is to get you stable and back to work. Our goal is to enable you to work, not to prove you need disability benefits. Sometimes STD companies will deny the claim despite all efforts. They may say that you can appeal if your provider gives additional information, which usually means they are looking for information that was not reported to them. We will always provide the most complete information we have, so unless additional information is available, we are not able to participate in the appeals process.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is Long-Term Disability (LTD)?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Long-Term Disability is an income-replacement benefit that provides a percentage of pre-disability earnings when a person is unable to work due to a qualifying illness or injury. LTD benefits are typically used when STD benefits run out and a person is not able to return to work. There may be a set limit on LTD, such as 2 years, 5 years, 10 years, or until a specific age is reached (like age 65).</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I feel I may qualify for Long-Term Disability, what do I do next?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>This depends on the benefits provider. Some employers offer LTD benefits, so the process would be essentially the same as requesting STD. The Social Security Administration (SSA) also has benefits for those who qualify through the SSDI and Supplemental Security Income (SSI) programs. Your condition must be expected to last more than one year or result in death. Typically, only Seriously Mentally Ill (SMI) patients qualify for LTD benefits through the SSA. You can get more information on applying for SSDI benefits online through the Social Security Administration website.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is there anything else I should know about FMLA, Short-Term Disability, and Long-Term Disability?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>You should know that while we will always make our best efforts to support patients who are in need of these benefits, we are not the ones making the final determination and do not have control over whether claims are approved or denied. For any questions regarding our policies and procedures related to FMLA and Disability, please contact your provider’s office.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3 style={{ fontSize: '35px' }}>Using The Patient Portal (Valant IO)</h3>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Accordion allowZeroExpanded>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How can I log into my patient portal (Valant IO)?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>To log in to Valant IO, visit <a href="https://www.valant.io/LogIn">Valant IO Login</a> and follow the detailed instructions provided on the User Guide. Ensure you have your correct login credentials ready.</p>
                                    <p>If your patient portal is already set up, you can access it by visiting the “Resources” tab on RPsych.com and selecting “Patient Portal”.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How can I customize my dashboard?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Valant IO offers a customizable dashboard for users. To learn how to customize your dashboard and make the most of its features, refer to the Dashboard section in the User Guide.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What should I do if I encounter issues or errors?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>If you experience any issues or errors while using Valant IO, please consult the Troubleshooting section of the User Guide for assistance and additional resources.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What can I use the patient portal (Valant IO) for?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Your patient portal can be used to update your personal information and to complete your new patient forms and/or any additional forms assigned by your provider. You may also view your upcoming appointment(s) information in your patient portal.</p>
                                    <p>We ask that you please refrain from changing your insurance information via the portal and instead contact the office to do so.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3 style={{ fontSize: '35px' }}>What is Ketamine Therapy?</h3>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Visit our infusions website to learn more: <a href='https://rpsych.com/ketamine'>https://rpsych.com/ketamine</a></p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3 style={{ fontSize: '35px' }}>What is TMS?</h3>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Visit our TMS website to learn more: <a href='https://rpsychtms.com/'>https://rpsychtms.com/</a></p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        )
    }
}

export default Accordion01;

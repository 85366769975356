import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "800px",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore : {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    showLess : {
        display: "inline",
        position: "relative",
        top: "0px",
    },
    iconHover : {
        "&:hover":{
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function SimplePopover() {
    const classes = useStyles();
    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText:<div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText:<div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 200, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        }
    }
    return (
        <div>
            <div className={classes.title}>
                •	Generic medications aren’t very profitable.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Ketamine has been around for so long that it is generic, produced by several companies, and used very frequently. This means that pharmaceutical companies cannot charge thousands of dollars per use the way they do for most new procedural medications, so they are not willing to spend billions of dollars on the necessary research for FDA approval of a new indication for Ketamine.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	New FDA indications require investment.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    The other way to obtain FDA approval is when enough research is generated over several years to establish the new treatment. We are making progress, but Supplemental New Drug applications require sponsors to submit a safety profile for the new treatment as well as provide comparisons with previously approved indications. It seems simple, but the application sponsor is generally a pharmaceutical company willing to go through the effort and expense (estimated around $50M) because they have a financial interest that they plan to recuperate. Generic drugs can be manufactured by anyone, so no single manufacturer could profit from a new indication for Ketamine.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	No insurance procedures exist.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Without FDA approval, insurances generally don’t create the necessary prior authorization procedures, billing codes, and fee schedules for doctors to get reimbursed for the procedure. Insurances can decide to move forward based on the generally accepted off-label use, but that only happens when they have a financial interest in the new treatment like when it saves them more money than it costs.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Ketamine use is new in psychiatry.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Insurance companies consider many treatments to be specialty-specific, and historically, Ketamine has most commonly been used by anesthesiologists. However, anesthesiologists obviously should not be treating depression any more than psychiatrists should be putting patients to sleep for surgery, but it is generally very difficult for a specialty-specific treatment to suddenly be approved under a new specialty.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Unique treatments require new approaches.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Ketamine infusion therapy is unique in many ways making it difficult for insurance companies to agree on how to implement it. There just has never been another treatment that is effective for so many mental health disorders, administered by IV under observation in an outpatient setting, and capable of producing safe and highly effective results. In contrast, when a new routine medication is approved, there is already a roadmap on how to implement its use.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Certification processes are needed.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Only psychiatrists with the proper training and experience should be contracted with insurance companies for administration of Ketamine treatment for mental health disorders, but there isn’t currently any certification recognized by insurance companies to assure doctors are qualified to administer the treatment. This means that unlike the doctors at Redemption Psychiatry who have a wealth of training and experience, insurance coverage may mean that other inexperienced psychiatrists could be financially encouraged to attempt to administer Ketamine infusions without the proper training and no regulation.
              </div>
            </ReactTextCollapse>
        </div>
    );
}
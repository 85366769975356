import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TestimonialTwo = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Tabs>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p style={{fontWeight:'500', fontSize: '24px'}}>To redeem the practice of Psychiatry for providers and patients, remove any stigma towards mental illness, and stay at the forefront of advancements in Psychiatric treatment.</p>
                            </div>
                            <div className="author-info">
                                <h6><span style={{textTransform:'none'}}>At Redemption we believe there is strength in seeking help. All too often the stigma associated with mental illness prevents those who need care from seeking it. We are doing our best to combat this attitude and assure our neighbors and friends that seeking and receiving mental health care is neither shameful nor weak.</span></h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabList className="testimonial-thumb-wrapper">
                        <Tab>
                        </Tab>
                    </TabList>
                </Tabs>
            </div>
        </div>
    )
}
export default TestimonialTwo

const BlogContent = [
    {
        images: '01',
        image: 'image-1',
        title: 'Ketamine Treatment: Is It The Future Of Depression Treatment?',
        category: 'TMS',
        link: "/KetamineTreatmentTheFutureOfDepressionTreatment"
    },
    {
        images: '02',
        image: 'image-2',
        title: 'Riding The Storm: Looking After Your Mental Health During The Pandemic',
        category: 'Psychiatry',
        link: "/LookingAfterYourMentalHealthDuringThePandemic"
    },

    {
        images: '03',
        title: 'All You Need To Know About Ketamine Infusion Therapy',
        category: 'Ketamine',
        link: "/AllYouNeedToKnowAboutKetamine"
    },
    {
        images: '04',
        title: 'Battling the Stigma around Mental Health Treatment',
        category: 'Development',
        link: "/BattlingTheStigmaAroundMentalHealthTreatment"
    },
    {
        images: '01',
        title: 'A big ticket gone to be an interesting ',
        category: 'Management'
    },
    {
        images: '02',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
]

export default BlogContent;
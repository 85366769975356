import React, { useState } from 'react';
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// import axios from 'axios'
import ContactTwo2 from "../elements/contact/ContactTwo2";

function ProfessionalResources(props) {
    // const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, errors } = useForm();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data: response } = await axios.get('https://rpsych.sfo3.digitaloceanspaces.com/OfficeList.json');
    //             setData(response);
    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const onSubmit = data => {
        console.log(data);
        // handle form data submission
    };
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Professional Resources' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--22" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Professional Resources</h2>
                                <p>Welcome to our Professional Resources page. We are dedicated to collaborating with healthcare professionals to provide comprehensive psychiatric care.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Sections */}
            <div className="service-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h2>Referrals</h2>
                                <p>If you are a healthcare provider looking to refer a patient to our practice, please use our online referral form.</p>
                            </div>
                            <div className="content text-center">
                                <Button onClick={() => setShowModal(true)} className="rn-button-style--2 btn-solid">Online Referral Form</Button>
                                <br /><br />
                                <Button className="rn-button-style--2 btn-solid">Download Referral Form (PDF)</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Discharge Planners Section */}
            <div className="service-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h2>For Discharge Planners</h2>
                                <p>Our team is committed to working with discharge planners to ensure a smooth transition of care.</p>
                            </div>
                            <div className="content text-center">
                                <p>Contact our Discharge Coordinator at <a href="mailto:discharge.coord@psychiatryoffice.com">discharge.coord@psychiatryoffice.com</a> or (555) 123-4578.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Discharge Planners Section */}

            {/* Start KAT Therapists Section */}
            <div className="service-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h2>KAT Therapists</h2>
                                <p>Connect with our network of licensed therapists trained in Ketamine-Assisted Therapy.</p>
                            </div>
                            <div className="content text-center">
                                <p>Contact our KAT Coordinator at <a href="mailto:kat.therapy@psychiatryoffice.com">kat.therapy@psychiatryoffice.com</a> or (555) 123-4589.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End KAT Therapists Section */}

            {/* Additional Sections */}
            <div className="service-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h2>Links to Forms and External Websites</h2>
                            </div>
                            <div className="content text-center">
                                <p><button href="#">Consent Forms</button></p>
                                <p><button href="#">Insurance Information</button></p>
                                <p><button>Professional Associations</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h2>Contact Us</h2>
                            </div>
                            <div className="content text-center">
                                <p>For any other professional inquiries or to learn more about our services, please do not hesitate to contact our office:</p>
                                <p>Psychiatry Medical Office<br />
                                123 Wellness Drive<br />
                                City, State, Zip<br />
                                Phone: (555) 123-4567<br />
                                Fax: (555) 123-4568<br />
                                Email: <a href="mailto:info@psychiatryoffice.com">info@psychiatryoffice.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Additional Sections */}

            {/* Contact Form from ContactTwo, if applicable */}
            <div className="rn-contact-page ptb--120 bg_color--1">
                <ContactTwo2 />
            </div>

            {/* Modal for Online Referral Form */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Online Referral Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Referring Provider Information */}
                        <div className="form-group">
                            <label>Provider's Name</label>
                            <input name="providerName" type="text" className="form-control" ref={register({ required: true })} />
                            {errors.providerName && <span>This field is required</span>}
                        </div>
                        <div className="form-group">
                            <label>Title</label>
                            <input name="providerTitle" type="text" className="form-control" ref={register} />
                        </div>
                        <div className="form-group">
                            <label>Organization</label>
                            <input name="providerOrganization" type="text" className="form-control" ref={register} />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input name="providerPhone" type="tel" className="form-control" ref={register} />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input name="providerEmail" type="email" className="form-control" ref={register} />
                        </div>

                        {/* Patient Information */}
                        <div className="form-group">
                            <label>Patient's Name</label>
                            <input name="patientName" type="text" className="form-control" ref={register({ required: true })} />
                            {errors.patientName && <span>This field is required</span>}
                        </div>
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <input name="patientDOB" type="date" className="form-control" ref={register} />
                        </div>
                        <div className="form-group">
                            <label>Contact Number</label>
                            <input name="patientContact" type="tel" className="form-control" ref={register} />
                        </div>
                        <div className="form-group">
                            <label>Insurance Provider</label>
                            <input name="patientInsurance" type="text" className="form-control" ref={register} />
                        </div>

                        {/* Reason for Referral */}
                        <div className="form-group">
                            <label>Reason for Referral</label>
                            <select name="referralReason" className="form-control" ref={register}>
                                <option value="depression">Depression</option>
                                <option value="anxiety">Anxiety</option>
                                <option value="bipolar">Bipolar Disorder</option>
                                <option value="ptsd">PTSD</option>
                                <option value="adhd">ADHD</option>
                                <option value="other">Other (please specify)</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Brief Summary of Patient's Condition and Treatment History</label>
                            <textarea name="patientSummary" className="form-control" ref={register}></textarea>
                        </div>
                        <div className="form-group">
                            <label>Additional Comments or Requests</label>
                            <textarea name="additionalComments" className="form-control" ref={register}></textarea>
                        </div>
                        <Button variant="primary" type="submit">Submit Referral</Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* End Sections */}<br /><br /><br />

            <Footer />
            {/* Start Back To Top */}
            <div className="backto-top-wrap">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </React.Fragment>
    )
}

export default ProfessionalResources;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// core components
import GridContainer from "../../component/Grid/GridContainer";
import Container from '@material-ui/core/Container';
import styles from "../../../public/assets/jss/material-kit-react/views/profilePage.js";
//sections
import FAQs from "./FAQ's/FAQlist.js";

const useStyles = makeStyles(styles);

export default function PricingPage(props) {
    const classes = useStyles();
    return (
        <div className={classNames(classes.main)}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <div className={classes.profilePrice}>
                        <Container maxWidth="lg" component="main" className={classes.heroContent}>
                            <div className={classes.namePrice}>
                                <br /><br /><br /><br /><br /><br />
                                <h2>Frequently Asked Questions</h2>
                                <h5>
                                    Click on any of the questions below or search for a keyword to learn more!
                                </h5>
                            </div>
                        </Container>
                    </div>
                </GridContainer>
                <FAQs />
                <GridContainer justify="center">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        DISCLAIMER
                    </Typography>
                    <Typography variant="body1" align="center" color="textSecondary" component="div">
                        It is important to fully educate yourself before pursuing any medical treatment. Deciding to undergo ketamine infusion therapy is no different, and we hope this information will be helpful in better understanding the treatment. Please note that the information contained on this webpage is not intended as an advertisement for treatment. You must be prescribed ketamine treatment by a mental health specialist. Like all medical treatments, we cannot guarantee results or promise there will not be any adverse effects. Although many patients experience total relief from their first infusion or after multiple infusions, it is possible for patients to have no response from treatment. Each individual’s response is unique and dependent on several factors, so you should know that your response may not be typical and should not be compared with the experiences of others or research outcomes.
                    </Typography><br></br>
                </GridContainer>
            </div>
        </div>
    );
}


import React from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import qandas from "./qandas.js"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#FFFFFF",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#FFFFFF",
    expansionIndicator: "#FFFFFF",
  },
  summary: {
    backgroundColor: "#2B4E90",
    color: "white"
  },
  typographyFAQ: {
    textAlign: "left",
    backgroundColor: "#E5E5E5",
  },
  textCenter: {
    textAlign: "center"
  }
}));



export default function GeneralFAQPanel() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const handleChange = e => {
    setSearchTerm(e.target.value);
  };
  React.useEffect(() => {
    const results = searchTerm ?
      qandas.filter(qanda =>
        qanda.question.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        qanda.answer.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) : qandas
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <div>
      <div className={classes.textCenter}>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleChange}
        />
      </div>
      <br />
      <div className={classes.root}>
        {searchResults.length > 0 ? (
          searchResults.map((qanda) => {
            return <ExpansionPanel key={qanda.id}>
              <ExpansionPanelSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                {qanda.question}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.typographyFAQ}>
                <Typography  component="div">
                  {qanda.answer2}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          })
        ) : (
            <div className={classes.textCenter}>
              Oops! We are sorry, there were no results for "{searchTerm}", please try another seach term.
           </div>
          )}
      </div>
      <br></br><br></br><br></br>
    </div>
  );
}

GeneralFAQPanel.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  id: PropTypes.string
}


import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select'
import Axios from "axios";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Modal, Form, Image } from 'react-bootstrap';


export default function NewPatientForm() {
    const { register, handleSubmit, watch, getValues, setValue, errors, control } = useForm();
    const [tel, setTel] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [age, setAge] = useState("")
    const [subscriber, setSubscriber] = useState("Yes")
    const [insurance, setInsurance] = useState("Yes")
    const [frontImage, setFrontImage] = useState(null);
    const [frontDriverImage, setFrontDriverImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [backDriverImage, setBackDriverImage] = useState(null);
    const [referralSource, setReferralSource] = useState("");


    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (type === 'front') {
                    setFrontImage({ dataURL: e.target.result, file });
                } else if (type === 'back') {
                    setBackImage({ dataURL: e.target.result, file });
                } else if (type === 'frontDriver') {
                    setFrontDriverImage({ dataURL: e.target.result, file });
                } else if (type === 'backDriver') {
                    setBackDriverImage({ dataURL: e.target.result, file });
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const onSubmit = (data, e) => {
        setIsLoading(true);
        setOpen(true);
        const formData = new FormData();

        if (frontImage) {
            formData.append('frontImage', frontImage.file);
        }
        if (backImage) {
            formData.append('backImage', backImage.file);
        }
        if (frontDriverImage) {
            formData.append('frontDriverImage', frontDriverImage.file);
        }
        if (backDriverImage) {
            formData.append('backDriverImage', backDriverImage.file);
        }

        // Append other form data to formData
        for (let key in data) {
            if (key !== "files") { // Skip 'files' as it's handled separately
                if (key === "office") {
                    // Stringify the office array
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
        }

        Axios.post('/api/contactforms/create-new-pt-email', formData).then(response => {
            // reset after form submit
            e.target.reset();
            setIsLoading(false);
            setTel("");
            // Reset file uploads
            setFrontImage(null)
            setBackImage(null)
            setFrontDriverImage(null)
            setBackDriverImage(null)
            setReferralSource("");
        }).catch(error => {
            console.error(error);
            setIsLoading(false);
        });
    }

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setSubscriber(watch('primarySubscriber'))
        setInsurance(watch('insurance'))
        setAge(moment().diff(new Date(watch('patientDOB')), 'years'))
        if (isNaN(moment().diff(new Date(watch('patientDOB')), 'years'))) {
            setAge(' ');
        } if (moment().diff(new Date(watch('patientDOB')), 'years') > 120) {
            setAge(' ')
        } if (moment().diff(new Date(watch('patientDOB')), 'years') < 0) {
            setAge(' ')
        }
    }, [watch])

    let options = [
        { value: "Gilbert", label: "Gilbert", },
        { value: "Phoenix", label: "Phoenix" },
        { value: "Chandler", label: "Chandler" },
        { value: "Mesa", label: "Mesa" }
    ]

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '50px',
            height: '50px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '50px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '50px',
        }),
    };

    return (
        <div className="">
            <div className="container">
                <div className="section-title text-left mb--10">
                    <h2 style={{ textAlign: 'center' }} className="title">New Patient Form</h2>
                    <div className="alert alert-primary alert-dismissible" style={{ marginBottom: '0px', textAlign: 'center' }} role="alert">
                        <b>If you are interested in a new patient appointment, please fill out this form as completely as possible and click submit at the bottom.</b>
                    </div>
                </div>
                <div className="form-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h4 style={{ margin: "0px", marginTop: "20px" }}>Patient Information:</h4>
                        <div className="row row--35 align-items-start">
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: "0px", marginTop: "10px" }}>First Name</label>
                                <input
                                    type="text"
                                    name="patientFirstName"
                                    placeholder="First Name *"
                                    ref={register({
                                        required: 'Please enter your first name',
                                        maxLength: {
                                            value: 30,
                                            message: 'Max length is 30',
                                        },
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.patientFirstName && errors.patientFirstName.message}</div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: "0px", marginTop: "10px" }}>Last Name</label>
                                <input
                                    type="text"
                                    name="patientLastName"
                                    placeholder="Last Name *"
                                    ref={register({
                                        required: 'Please enter your last name',
                                        maxLength: {
                                            value: 30,
                                            message: 'Max length is 30',
                                        },
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.patientLastName && errors.patientLastName.message}</div>
                            </div>
                        </div>
                        <div className="row row--35 align-items-start">
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: '0px', marginTop: "10px" }}>Date of Birth</label>
                                <input
                                    name="patientDOB"
                                    type="date"
                                    ref={register({ required: 'Please enter patient Date of Birth' })}
                                />
                                <div style={{ color: "red" }}>{errors.patientDOB && errors.patientDOB.message}</div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: '0px', marginTop: "10px" }}>Current Age</label>
                                <input
                                    placeholder="Age *"
                                    defaultValue={age > 0 && age < 120 ? age : ''}
                                    name="age"
                                    type="text"
                                    ref={register()}
                                />
                            </div>
                            <div className="col-12">
                                {age > 62 ?
                                    <div>
                                        <p style={{ color: "red" }}>Please notice, we are not contracted with medicare and not all of our provider's see geriatric patients</p>
                                    </div>
                                    : age < 18 && age > 0 ?
                                        <div className="row row--35 align-items-start">
                                            <div className="col-sm-12 col-lg-4">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Guarantor's Relationship To Patient</label>
                                                <input
                                                    type="text"
                                                    name="guarantorRelationship"
                                                    placeholder="Mother, Father, Legal Guardian, etc. *"
                                                    ref={register({ required: 'Please enter Guarantor Relationship To Patient' })}
                                                />
                                                <div style={{ color: "red" }}>{errors.guarantorRelationship && errors.guarantorRelationship.message}</div>
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Guarantor's First Name</label>
                                                <input
                                                    type="text"
                                                    name="guarantorFirstName"
                                                    placeholder="Guarantor's First Name *"
                                                    ref={register({ required: 'Please enter Guarantor First Name' })}
                                                />
                                                <div style={{ color: "red" }}>{errors.guarantorFirstName && errors.guarantorFirstName.message}</div>
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Guarantor's Last Name</label>
                                                <input
                                                    type="text"
                                                    name="guarantorLastName"
                                                    placeholder="Guarantor's Last Name *"
                                                    ref={register({ required: 'Please enter Guarantor Last Name' })}
                                                />
                                                <div style={{ color: "red" }}>{errors.guarantorLastName && errors.guarantorLastName.message}</div>
                                            </div>
                                        </div>
                                        :
                                        ' '
                                }
                            </div>
                        </div>
                        <div className="row row--35 align-items-start">
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: "0px", marginTop: "10px" }}>Phone # </label>
                                <NumberFormat
                                    name="tel"
                                    value={tel}
                                    onChange={e => setTel(e.target.value)}
                                    placeholder="(555) 555-5555 *"
                                    format="(###) ###-####"
                                    mask="_"
                                    getInputRef={register({
                                        pattern: {
                                            value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                                            message: 'Invalid phone number',
                                        },
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.tel && errors.tel.message}</div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: "0px", marginTop: "10px" }}>E-Mail Address</label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Example@email.com *"
                                    ref={register({
                                        required: 'Please enter your email',
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                />
                                <div style={{ color: "red" }}>{errors.email && errors.email.message}</div>
                            </div>
                        </div>
                        <div className="row row--35 align-items-start">
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: "0px", marginTop: "10px" }}>Sex</label>
                                <select
                                    name="gender"
                                    ref={register()}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label style={{ margin: "0px", marginTop: "10px" }}>Gender</label>
                                <select
                                    name="genderID"
                                    ref={register()}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Transgender Male">Transgender Male</option>
                                    <option value="Transgender Female">Transgender Female</option>
                                    <option value="Transgender (as non-binary)">Transgender (as non-binary)</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                    <option value="Gender-queer">Gender-queer</option>
                                    <option value="Two-Spirit">Two-Spirit</option>
                                    <option value="Questioning/Not Sure">Questioning/Not Sure</option>
                                    <option value="Choose not to disclose">Choose not to disclose</option>
                                </select>
                            </div>
                        </div>
                        <br />
                        <div className="row row--35 align-items-start">
                            <div className="col-12">
                                <label style={{ margin: "0px", marginTop: "10px" }}>Address</label>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Street Address *"
                                    ref={register({ required: 'Please enter your Address' })}
                                />
                                <div style={{ color: "red" }}>{errors.address && errors.address.message}</div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <label style={{ margin: "0px", marginTop: "10px" }}>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City *"
                                    ref={register({ required: 'Please enter your City' })}
                                />
                                <div style={{ color: "red" }}>{errors.city && errors.city.message}</div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <label style={{ margin: "0px", marginTop: "10px" }}>State</label>
                                <select
                                    name="state"
                                    ref={register()}>
                                    <option value="AZ">Arizona</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <label style={{ margin: "0px", marginTop: "10px" }}>Zip Code</label>
                                <input
                                    type="text"
                                    name="zip"
                                    placeholder="Zip Code *"
                                    ref={register({ required: 'Please enter your Zip Code' })}
                                />
                                <div style={{ color: "red" }}>{errors.zip && errors.zip.message}</div>
                            </div>
                        </div>
                        <div className="row row--35 align-items-start">
                            <div className="col-12">
                                <br /><h4 style={{ margin: "0px", marginTop: "10px" }}>Insurance Information:</h4>
                            </div>
                            <div className="col-12">
                                <label style={{ margin: "0px", marginTop: "10px" }}>What Insurance do you have?:</label>
                                <select className="form-control" name="insurance" style={{ maxWidth: '565px' }} ref={register}>
                                    <option value="Cash Pay">Cash Pay</option>
                                    <option value="ACN">ACN</option>
                                    <option value="ACN PPO">ACN PPO</option>
                                    <option value="Aetna">Aetna</option>
                                    <option value="Allied ASO">Allied ASO</option>
                                    <option value="Ambetter">Ambetter</option>
                                    <option value="All Savers-UHC">All Savers-UHC</option>
                                    <option value="Ameriben">Ameriben</option>
                                    <option value="Beacon">Beacon</option>
                                    <option value="BCBS AZ">BCBS AZ</option>
                                    <option value="Bright Health">Bright Health</option>
                                    <option value="Bright Health Advantage">Bright Health Advantage</option>
                                    <option value="Champ VA">Champ VA</option>
                                    <option value="Cigna">Cigna</option>
                                    <option value="Compsych">Compsych</option>
                                    <option value="GEHA-ASA">GEHA-ASA</option>
                                    <option value="Gilsbar">Gilsbar</option>
                                    <option value="Golden Rule">Golden Rule</option>
                                    <option value="Humana">Humana</option>
                                    <option value="HMC">HMC</option>
                                    <option value="MHN Better">MHN Better</option>
                                    <option value="Magellan">Magellan</option>
                                    <option value="Meritian">Meritian</option>
                                    <option value="MHN">MHN</option>
                                    <option value="Oscar-UHC">Oscar-UHC</option>
                                    <option value="Summit">Summit</option>
                                    <option value="Tricare for Life">Tricare for Life</option>
                                    <option value="Tri-care West Region">Tri-care West Region</option>
                                    <option value="Triwest VA">Triwest VA</option>
                                    <option value="UBH">UBH</option>
                                    <option value="UHC">UHC</option>
                                    <option value="UHC Student Resource">UHC Student Resource</option>
                                    <option value="UMR">UMR</option>
                                    <option value="Insurance Not Listed">Insurance Not Listed</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            {insurance === "Cash Pay" ?
                                <div></div>
                                : insurance === "Insurance Not Listed" ?
                                    <div>
                                        <p style={{ color: 'red' }}>Unfortunately, if you're insurance is not on this list you will have to select 'Cash Pay'. However,  after you've paid with cash, we can provide you with a superbill to submit to your insurance company to request reimbursement.</p>
                                    </div>
                                    :
                                    <div>
                                        <div className="row row--35 align-items-start">
                                            <div className="col-sm-12 col-lg-4">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Insurance ID#</label>
                                                <input
                                                    type="text"
                                                    name="insuranceID"
                                                    placeholder="Insurance ID # *"
                                                    ref={register()}
                                                />
                                                <div style={{ color: "red" }}>{errors.patientFirstName && errors.patientFirstName.message}</div>
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Insurance Group Number</label>
                                                <input
                                                    type="text"
                                                    name="insuranceGroup"
                                                    placeholder="Insurance Group Number *"
                                                    ref={register()}
                                                />
                                                <div style={{ color: "red" }}>{errors.patientLastName && errors.patientLastName.message}</div>
                                            </div>
                                            <div className="col-sm-12 col-lg-4"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-6">
                                                <Form.Group>
                                                    <Form.Label style={{ margin: "0px", marginTop: "10px" }}>Upload Front of Insurance Card</Form.Label><br />
                                                    <Form.File
                                                        style={{ maxWidth: '250px' }}
                                                        id="front-image-input"
                                                        custom
                                                        label="Choose file"
                                                        onChange={(e) => handleFileChange(e, 'front')}
                                                        name="frontImage"
                                                        ref={register}
                                                    /><br />
                                                    {frontImage && (
                                                        <Image style={{ maxHeight: '300px' }} src={frontImage.dataURL} alt="Front Preview" thumbnail />
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <Form.Group>
                                                    <Form.Label style={{ margin: "0px", marginTop: "10px" }}>Upload Back of Insurance Card</Form.Label><br />
                                                    <Form.File
                                                        style={{ maxWidth: '250px' }}
                                                        id="back-image-input"
                                                        custom
                                                        label="Choose file"
                                                        onChange={(e) => handleFileChange(e, 'back')}
                                                        name="backImage"
                                                        ref={register}
                                                    /><br />
                                                    {backImage && (
                                                        <Image style={{ maxHeight: '300px' }} src={backImage.dataURL} alt="Back Preview" thumbnail />
                                                    )}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-6">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Upload Front of Driver's License</label><br />
                                                <Form.File
                                                    style={{ maxWidth: '250px' }}
                                                    id="front-driver-image-input"
                                                    custom
                                                    label="Choose file"
                                                    onChange={(e) => handleFileChange(e, 'frontDriver')}
                                                    name="frontDriverImage"
                                                    ref={register}
                                                /><br />
                                                {frontDriverImage && (
                                                    <Image style={{ maxHeight: '300px' }} src={frontDriverImage.dataURL} alt="Front Preview" thumbnail />
                                                )}
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <label style={{ margin: "0px", marginTop: "10px" }}>Upload Back of Driver's License</label><br />
                                                <Form.File
                                                    style={{ maxWidth: '250px' }}
                                                    id="back-driver-image-input"
                                                    custom
                                                    label="Choose file"
                                                    onChange={(e) => handleFileChange(e, 'backDriver')}
                                                    name="backDriverImage"
                                                    ref={register}
                                                /><br />
                                                {backDriverImage && (
                                                    <Image style={{ maxHeight: '300px' }} src={backDriverImage.dataURL} alt="Back Preview" thumbnail />
                                                )}
                                            </div>
                                        </div><br />
                                        <div className="col-12">
                                            <label>Is the patient also the primary insurance subscriber? </label>
                                            <label style={{ display: 'inline-block', position: 'relative' }}>Yes
                                                <input
                                                    style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '5px' }}
                                                    type="radio"
                                                    name="primarySubscriber"
                                                    value="Yes"
                                                    onClick={() => {
                                                        setValue('subscriberRelationship', 'Self')
                                                        setValue('subscriberFirstName', getValues('patientFirstName'))
                                                        setValue('subscriberLastName', getValues('patientLastName'))
                                                        setValue('subscriberDOB', getValues('patientDOB'))
                                                        setValue('subscriberGender', getValues('gender'))
                                                        setValue('subscriberAddress', getValues('address'))
                                                        setValue('subscriberCity', getValues('city'))
                                                        setValue('subscriberState', getValues('state'))
                                                        setValue('subscriberZip', getValues('zip'))
                                                    }}
                                                    ref={register({ required: 'Please select if the patient is also the primary insurance subscriber' })}
                                                />
                                            </label>
                                            <label style={{ display: 'inline-block', position: 'relative', left: '75px' }}>No
                                                <input
                                                    style={{ height: '20px', display: 'inline-block', position: 'absolute', marginLeft: '5px' }}
                                                    type="radio"
                                                    name="primarySubscriber"
                                                    value="No"
                                                    onClick={() => {
                                                        setValue('subscriberRelationship', '')
                                                        setValue('subscriberFirstName', '')
                                                        setValue('subscriberLastName', '')
                                                        setValue('subscriberDOB', '')
                                                        setValue('subscriberGender', '')
                                                        setValue('subscriberAddress', '')
                                                        setValue('subscriberCity', '')
                                                        setValue('subscriberState', '')
                                                        setValue('subscriberZip', '')
                                                    }}
                                                    ref={register()}
                                                />
                                            </label>
                                            <div style={{ color: "red" }}>{errors.primarySubscriber && errors.primarySubscriber.message}</div>
                                        </div>
                                        {subscriber === "No" ?
                                            <div>
                                                <div className="row row--35 align-items-start" style={{ marginTop: '7px' }}>
                                                    <div className="col-sm-12 col-lg-4">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's relationship to Patient:</label>
                                                        <input
                                                            type="text"
                                                            name="subscriberRelationship"
                                                            defaultValue=''
                                                            placeholder="Spouse, Domestic Partner, Child, etc. *"
                                                            ref={register({ required: 'Please enter Subscriber relationship to Patient' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberRelationship && errors.subscriberRelationship.message}</div>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-4">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's First Name</label>
                                                        <input
                                                            type="text"
                                                            name="subscriberFirstName"
                                                            defaultValue=''
                                                            placeholder="First Name *"
                                                            ref={register({ required: 'Please enter Subscriber First Name' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberFirstName && errors.subscriberFirstName.message}</div>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-4">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's Last Name</label>
                                                        <input
                                                            type="text"
                                                            name="subscriberLastName"
                                                            defaultValue=''
                                                            placeholder="Last Name *"
                                                            ref={register({ required: 'Please enter Subscriber Last Name' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberLastName && errors.subscriberLastName.message}</div>
                                                    </div>
                                                </div>
                                                <div className="row row--35 align-items-start">
                                                    <div className="col-sm-12 col-lg-6">
                                                        <label style={{ margin: "0px", marginTop: "10px" }} htmlFor="subscriberDOB">Subscriber's Date of Birth:</label>
                                                        <input
                                                            name="subscriberDOB"
                                                            defaultValue=''
                                                            type="date"
                                                            ref={register({ required: 'Please enter Subscriber Date of Birth' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberDOB && errors.subscriberDOB.message}</div>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's Gender: </label>
                                                        <select
                                                            name="subscriberGender"
                                                            ref={register()}>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row row--35 align-items-start">
                                                    <div className="col-12">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's Address</label>
                                                        <input
                                                            type="text"
                                                            name="subscriberAddress"
                                                            placeholder="Street Address *"
                                                            ref={register({ required: 'Please enter Subscriber Address' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberAddress && errors.subscriberAddress.message}</div>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-4">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>City</label>
                                                        <input
                                                            type="text"
                                                            name="subscriberCity"
                                                            placeholder="City *"
                                                            ref={register({ required: 'Please enter Subscriber City' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberCity && errors.subscriberCity.message}</div>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-4">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>State</label>
                                                        <select
                                                            name="subscriberState"
                                                            ref={register()}>
                                                            <option value="AZ">Arizona</option>
                                                            <option value="AL">Alabama</option>
                                                            <option value="AK">Alaska</option>
                                                            <option value="AR">Arkansas</option>
                                                            <option value="CA">California</option>
                                                            <option value="CO">Colorado</option>
                                                            <option value="CT">Connecticut</option>
                                                            <option value="DE">Delaware</option>
                                                            <option value="DC">District Of Columbia</option>
                                                            <option value="FL">Florida</option>
                                                            <option value="GA">Georgia</option>
                                                            <option value="HI">Hawaii</option>
                                                            <option value="ID">Idaho</option>
                                                            <option value="IL">Illinois</option>
                                                            <option value="IN">Indiana</option>
                                                            <option value="IA">Iowa</option>
                                                            <option value="KS">Kansas</option>
                                                            <option value="KY">Kentucky</option>
                                                            <option value="LA">Louisiana</option>
                                                            <option value="ME">Maine</option>
                                                            <option value="MD">Maryland</option>
                                                            <option value="MA">Massachusetts</option>
                                                            <option value="MI">Michigan</option>
                                                            <option value="MN">Minnesota</option>
                                                            <option value="MS">Mississippi</option>
                                                            <option value="MO">Missouri</option>
                                                            <option value="MT">Montana</option>
                                                            <option value="NE">Nebraska</option>
                                                            <option value="NV">Nevada</option>
                                                            <option value="NH">New Hampshire</option>
                                                            <option value="NJ">New Jersey</option>
                                                            <option value="NM">New Mexico</option>
                                                            <option value="NY">New York</option>
                                                            <option value="NC">North Carolina</option>
                                                            <option value="ND">North Dakota</option>
                                                            <option value="OH">Ohio</option>
                                                            <option value="OK">Oklahoma</option>
                                                            <option value="OR">Oregon</option>
                                                            <option value="PA">Pennsylvania</option>
                                                            <option value="RI">Rhode Island</option>
                                                            <option value="SC">South Carolina</option>
                                                            <option value="SD">South Dakota</option>
                                                            <option value="TN">Tennessee</option>
                                                            <option value="TX">Texas</option>
                                                            <option value="UT">Utah</option>
                                                            <option value="VT">Vermont</option>
                                                            <option value="VA">Virginia</option>
                                                            <option value="WA">Washington</option>
                                                            <option value="WV">West Virginia</option>
                                                            <option value="WI">Wisconsin</option>
                                                            <option value="WY">Wyoming</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-4">
                                                        <label style={{ margin: "0px", marginTop: "10px" }}>Zip Code</label>
                                                        <input
                                                            type="text"
                                                            name="subscriberZip"
                                                            placeholder="Zip Code *"
                                                            ref={register({ required: 'Please enter Subscriber Zip Code' })}
                                                        />
                                                        <div style={{ color: "red" }}>{errors.subscriberZip && errors.subscriberZip.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : subscriber === "Yes" ?
                                                <div>
                                                    <div className="row row--35 align-items-start" style={{ marginTop: '7px' }}>
                                                        <div className="col-sm-12 col-lg-4">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's relationship to Patient:</label>
                                                            <input
                                                                type="text"
                                                                name="subscriberRelationship"
                                                                defaultValue='Self'
                                                                placeholder="Spouse, Domestic Partner, Child, etc. *"
                                                                ref={register({ required: 'Please enter Subscriber relationship to Patient' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberRelationship && errors.subscriberRelationship.message}</div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-4">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's First Name</label>
                                                            <input
                                                                type="text"
                                                                name="subscriberFirstName"
                                                                defaultValue={getValues('patientFirstName')}
                                                                placeholder="First Name *"
                                                                ref={register({ required: 'Please enter Subscriber First Name' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberFirstName && errors.subscriberFirstName.message}</div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-4">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's Last Name</label>
                                                            <input
                                                                type="text"
                                                                name="subscriberLastName"
                                                                defaultValue={getValues('patientLastName')}
                                                                placeholder="Last Name *"
                                                                ref={register({ required: 'Please enter Subscriber Last Name' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberLastName && errors.subscriberLastName.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row row--35 align-items-start">
                                                        <div className="col-sm-12 col-lg-6">
                                                            <label style={{ margin: "0px", marginTop: "10px" }} htmlFor="subscriberDOB">Subscriber's Date of Birth:</label>
                                                            <input
                                                                name="subscriberDOB"
                                                                defaultValue={getValues('patientDOB')}
                                                                type="date"
                                                                ref={register({ required: 'Please enter Subscriber Date of Birth' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberDOB && errors.subscriberDOB.message}</div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's gender? </label>
                                                            <select
                                                                name="subscriberGender"
                                                                defaultValue={getValues('gender')}
                                                                ref={register()}>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row row--35 align-items-start">
                                                        <div className="col-12">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>Subscriber's Address</label>
                                                            <input
                                                                type="text"
                                                                name="subscriberAddress"
                                                                defaultValue={getValues('address')}
                                                                placeholder="Street Address *"
                                                                ref={register({ required: 'Please enter Subscriber Address' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberAddress && errors.subscriberAddress.message}</div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-4">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>City</label>
                                                            <input
                                                                type="text"
                                                                name="subscriberCity"
                                                                defaultValue={getValues('city')}
                                                                placeholder="City *"
                                                                ref={register({ required: 'Please enter Subscriber City' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberCity && errors.subscriberCity.message}</div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-4">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>State</label>
                                                            <select
                                                                name="subscriberState"
                                                                defaultValue={getValues('state')}
                                                                ref={register()}>
                                                                <option value="AZ">Arizona</option>
                                                                <option value="AL">Alabama</option>
                                                                <option value="AK">Alaska</option>
                                                                <option value="AR">Arkansas</option>
                                                                <option value="CA">California</option>
                                                                <option value="CO">Colorado</option>
                                                                <option value="CT">Connecticut</option>
                                                                <option value="DE">Delaware</option>
                                                                <option value="DC">District Of Columbia</option>
                                                                <option value="FL">Florida</option>
                                                                <option value="GA">Georgia</option>
                                                                <option value="HI">Hawaii</option>
                                                                <option value="ID">Idaho</option>
                                                                <option value="IL">Illinois</option>
                                                                <option value="IN">Indiana</option>
                                                                <option value="IA">Iowa</option>
                                                                <option value="KS">Kansas</option>
                                                                <option value="KY">Kentucky</option>
                                                                <option value="LA">Louisiana</option>
                                                                <option value="ME">Maine</option>
                                                                <option value="MD">Maryland</option>
                                                                <option value="MA">Massachusetts</option>
                                                                <option value="MI">Michigan</option>
                                                                <option value="MN">Minnesota</option>
                                                                <option value="MS">Mississippi</option>
                                                                <option value="MO">Missouri</option>
                                                                <option value="MT">Montana</option>
                                                                <option value="NE">Nebraska</option>
                                                                <option value="NV">Nevada</option>
                                                                <option value="NH">New Hampshire</option>
                                                                <option value="NJ">New Jersey</option>
                                                                <option value="NM">New Mexico</option>
                                                                <option value="NY">New York</option>
                                                                <option value="NC">North Carolina</option>
                                                                <option value="ND">North Dakota</option>
                                                                <option value="OH">Ohio</option>
                                                                <option value="OK">Oklahoma</option>
                                                                <option value="OR">Oregon</option>
                                                                <option value="PA">Pennsylvania</option>
                                                                <option value="RI">Rhode Island</option>
                                                                <option value="SC">South Carolina</option>
                                                                <option value="SD">South Dakota</option>
                                                                <option value="TN">Tennessee</option>
                                                                <option value="TX">Texas</option>
                                                                <option value="UT">Utah</option>
                                                                <option value="VT">Vermont</option>
                                                                <option value="VA">Virginia</option>
                                                                <option value="WA">Washington</option>
                                                                <option value="WV">West Virginia</option>
                                                                <option value="WI">Wisconsin</option>
                                                                <option value="WY">Wyoming</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-4">
                                                            <label style={{ margin: "0px", marginTop: "10px" }}>Zip Code</label>
                                                            <input
                                                                type="text"
                                                                name="subscriberZip"
                                                                defaultValue={getValues('zip')}
                                                                placeholder="Zip Code *"
                                                                ref={register({ required: 'Please enter Subscriber Zip Code' })}
                                                            />
                                                            <div style={{ color: "red" }}>{errors.subscriberZip && errors.subscriberZip.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div></div>}
                                    </div>}

                        </div>
                        <br /><h4 style={{ margin: "0px", marginTop: "10px" }}>Additional Information:</h4>
                        <div className="row row--35 align-items-start">
                            <div className="col-sm-12 col-lg-6">
                                <label htmlFor="office">Which office(s) would you prefer to be seen at? (you can select multiple)</label>
                                <Controller
                                    control={control}
                                    name="office"
                                    as={Select}
                                    isMulti
                                    options={options}
                                    styles={customStyles}
                                    defaultValue={[]}
                                />
                                {errors.office && <span style={{ marginRight: '50px', color: 'red' }}> you must select at least 1 office to continue...</span>}
                            </div>
                        </div><br />
                        <div className="row row--35 align-items-start">
                            <div className="col-sm-12 col-lg-12">
                                <label htmlFor="therapist">Are you currently working with a therapist? If so, enter therapist name or office & phone number below. </label>
                                <div className="row row--35 align-items-start">
                                    <div className="col-sm-12 col-lg-6">
                                        <label htmlFor="therapist">Current therapist's name or office/facility: </label>
                                        <input
                                            type="text"
                                            name="therapist"
                                            placeholder="Enter current therapist's name or office/facility (or leave blank) *"
                                            ref={register()}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <label htmlFor="therapist">Current therapist's phone number: </label>
                                        <input
                                            type="text"
                                            name="therapistPhone"
                                            placeholder="Enter current therapist's phone number (or leave blank) *"
                                            ref={register()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div><br />
                        <div className="row row--35 align-items-start">
                            <div className="col-12">
                                <label style={{ margin: '0px', marginRight: '10px' }}>Are you being discharged from a hospital?</label>
                                <select
                                    style={{ width: '200px' }}
                                    name="hospitalDischarge"
                                    ref={register()}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <br /><br />
                            <div className="col-12">
                                <label style={{ margin: '0px', marginTop: '8px', marginRight: '10px' }}>What is the primary reason for your visit? </label>
                                <textarea
                                    type="text"
                                    name="message"
                                    placeholder="Describe your primary reason for wanting a visit *"
                                    ref={register({ required: 'Please write a message you would like to send us.' })}
                                />
                                <div style={{ color: "red" }}>{errors.message && errors.message.message}</div>
                            </div>
                            <div className="col-12">
                                <br /><label style={{ margin: '0px', marginTop: '8px', marginRight: '10px' }}>Preferred contact method? </label>
                                <select
                                    style={{ width: '200px' }}
                                    name="contactMethod"
                                    ref={register()}>
                                    <option value="Email">Email</option>
                                    <option value="Phone">Phone</option>
                                </select>
                            </div>
                            <br /><br />
                            <div className="col-12">
                                <br /><label style={{ margin: '0px', marginTop: '8px', marginRight: '10px' }}>What is the best time to reach you?</label>
                                <select
                                    style={{ width: '200px' }}
                                    name="time"
                                    ref={register()}>
                                    <option value="Morning">Morning</option>
                                    <option value="Afternoon">Afternoon</option>
                                    <option value="Evening">Evening</option>
                                </select>
                            </div>
                            <br /><br />
                            <div className="col-12">
                                <br /><label style={{ margin: '0px', marginTop: '8px', marginRight: '10px' }}>How did you hear about us?</label>
                                <select
                                    style={{ width: '200px' }}
                                    name="heardAboutUs"
                                    ref={register()}
                                    onChange={(e) => setReferralSource(e.target.value)}>
                                    <option value="Healthcare Provider">Healthcare Provider</option>
                                    <option value="Health Insurance">Health Insurance</option>
                                    <option value="Internet Search">Internet Search</option>
                                    <option value="Returning Patient">Returning Patient</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Word of Mouth">Word of Mouth</option>
                                </select>
                            </div>
                            {referralSource === "Word of Mouth" && (
                                <div className="col-12">
                                    <br />
                                    <label style={{ margin: '0px', marginTop: '8px', marginRight: '10px' }}>
                                        Who referred you?
                                    </label>
                                    <input
                                        type="text"
                                        name="referrerName"
                                        ref={register()}
                                        style={{ width: '200px' }}
                                    />
                                </div>
                            )}
                        </div>
                        <br /><br />
                        <div style={{ justifyContent: 'center' }} className="row row--35 align-items-start">
                            <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                        </div>
                    </form>
                </div>
                <Modal show={open} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {isLoading ? (
                            <div style={{ textAlign: "center" }}>
                                One moment please, <br />
                                sending email with attachments to our staff...
                            </div>
                        ) : (
                            <>
                                <h3 style={{ textAlign: "center", margin: "auto" }}>Thank You!</h3>
                                <div style={{ textAlign: "center" }}>
                                    A member of our staff will return your message as soon as possible. <br />
                                    Have a nice day!
                                </div>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="rn-button-style--2 btn-solid" onClick={handleClose}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}
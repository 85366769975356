import React, { Component } from "react";
import { Element } from 'react-scroll';

const ServiceList = [
    {
        icon: '01',
        title: 'IV Ketamine',
        description: 'Experience the transformative power of IV Ketamine therapy. This cutting-edge treatment offers new hope for individuals struggling with conditions such as depression, anxiety, OCD, and PTSD. With controlled and supervised medical administration in a relaxing environment, IV Ketamine has shown remarkable results in providing relief and promoting mental well-being. Please note that IV Ketamine therapy is not covered by most insurances (TriWestVA is the exception). We offer flexible payment options to make it accessible to those seeking this transformative treatment.'
    },
    {
        icon: '02',
        title: 'IM Ketamine Injections',
        description: `Our IM Ketamine injections provide a convenient and insurance-covered option for those seeking effective treatment for mental health conditions. Administered by our experienced medical professionals, these injections offer the benefits of ketamine therapy in a tailored and personalized approach. Discover the potential of IM Ketamine injections for enhancing your emotional well-being.`
    },
    {
        icon: '03',
        title: 'Ketamine-assisted Therapy (KAT)',
        description: 'Combine the power of ketamine with therapeutic support through our Ketamine-assisted Therapy (KAT) program. This comprehensive approach integrates the therapeutic process with the benefits of ketamine treatment, facilitating a deeper healing experience. Please note that while ketamine infusion therapy itself may not be covered by insurance, the accompanying psychotherapy sessions in our KAT program may be eligible for coverage. Explore the insurance coverage options and find out how to maximize the benefits of this integrated treatment approach.'
    },
]

class KetamineServices extends Component {
    render() {
        return (
            <React.Fragment>
                <Element name="services">
                    <div style={{ paddingTop: "70px" }}></div>
                    <button id="services" style={{ display: 'block', position: "relative", top: "-70px", visibility: "hidden" }}></button>
                    <div className="service-area ptb--20 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Services</h2>
                                        {/* <p>What to expect from a typical infusion with us.</p> */}
                                    </div>
                                    <div className="row">
                                        {ServiceList.map((val, i) => (
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                <div className="service service__style--1">
                                                    <div className="icon d-flex justify-content-center">
                                                        <img src={`/assets/images/icons/icon-${val.icon}.png`} height='150px' alt="Digital Agency" />
                                                    </div>
                                                    <div className="content">
                                                        <h4 className="title d-flex justify-content-center text-center">{val.title}</h4>
                                                        <p>{val.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
            </React.Fragment>
        )
    }
}
export default KetamineServices;
import React, { useState, useEffect, useRef } from "react";
// import { NavLink } from 'react-router-dom';
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Button from "../elements/team/Button";
import Biographies from "./team/Biographies"
import axios from 'axios';
import { useQueryStringKey } from 'use-route-as-state'



function Team(props) {
    const [activeButton, setActiveButton] = useState('')
    const [data, setData] = useState([])
    const [url, setURL] = useQueryStringKey('provider', 'none selected')
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        const fetchData = async () => {
          try {
            const { data: response } = await axios.get('https://rpsych.sfo3.digitaloceanspaces.com/ProviderList.json');
            // Create a new map to group providers by ID
            const providersMap = new Map();
            response.forEach((provider) => {
              // Ignore virtual offices
              if (provider.OfficeName === 'Virtual') return;
      
              // Check if the provider is already in the map
              if (!providersMap.has(provider.ID_Provider)) {
                // If not, add them with an array containing their first office
                providersMap.set(provider.ID_Provider, {
                  ...provider,
                  Offices: [provider.OfficeName]
                });
              } else {
                // If so, add the new office to their Offices array
                const existingProvider = providersMap.get(provider.ID_Provider);
                existingProvider.Offices.push(provider.OfficeName);
              }
            });
      
            // Convert the map values to an array and sort it
            const sortedProviders = Array.from(providersMap.values())
              .sort(alphabetically(true));
      
            // Update the data state with the sorted providers
            setData(sortedProviders);
          } catch (error) {
            console.error(error.message);
          }
        }
      
        fetchData();
      }, []);
      
    function alphabetically(ascending) {
        return function (a, b) {
            // equal items sort equally
            if (a.ListOrder === b.ListOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.ListOrder === null) {
                return 1;
            }
            else if (b.ListOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a.ListOrder < b.ListOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a.ListOrder < b.ListOrder ? 1 : -1;
            }
        };
    }
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Provider Biographies' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--20" data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title color-1">Provider Biographies</h2>
                                <p>Select a provider to reveal their profile.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Resource Area */}
            <div className="service-area ptb--20 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <div ref={myRef}></div>
                                <div style={{ paddingTop: '20px' }} >
                                    <Biographies
                                        currentProvider={data.filter(function (provider) {
                                            return provider.DisplayName === url
                                        })}
                                    />
                                    <br />
                                </div>
                                <div className="row">
                                    {data.map((item, index) => (
                                        <Button
                                            key={index}
                                            id={index}
                                            DisplayName={item.DisplayName}
                                            FirstName={item.FirstName}
                                            LastName={item.LastName}
                                            PictureLink={item.PictureLink}
                                            CurrentlyAcceptingPatients={item.CurrentlyAcceptingPatients}
                                            setActiveButton={setActiveButton}
                                            executeScroll={executeScroll}
                                            setURL={setURL}
                                            Offices={item.Offices}
                                            url={url}
                                            active={activeButton === index ? true : false}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Resource Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    );
};

export default Team;

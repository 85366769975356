const PodcastContent = [
    {
        images: '01',
        image: 'podcast-01',
        title: 'Episode 1',
        link: "https://www.youtube.com/watch?v=iMF3MhyczvU"
    },
    {
        images: '02',
        image: 'podcast-02',
        title: 'Episode 2',
        link: "https://www.youtube.com/watch?v=71sPw_oghVg"
    },
    {
        images: '03',
        image: 'podcast-03',
        title: 'Episode 3',
        link: "https://www.youtube.com/watch?v=xxF3Jsu18-w"
    },
    {
        images: '04',
        image: 'podcast-04',
        title: 'Episode 4',
        link: "https://www.youtube.com/watch?v=LDL2GsLE0gA"
    }
]

export default PodcastContent;